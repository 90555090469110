import { IsHavingAdvancedPermissions, getOsInfo, getBrowerInfo, IsBrowserSupportLocalFonts, GetLocalFontsPermissionState } from "./ThinkEditor.Utils.js";
export class BitItem {
    constructor(name, fieldWidth) {
        this.name = name;
        this.fieldWidth = fieldWidth;
        this.valueMask = 0; //构造函数自动计算
        this.mask = 0; //构造函数自动计算
        this.shift = 0; //构造函数自动计算
    }
}
export class BitFields {
    constructor(bitItems) {
        this.all = 0x00;
        if (bitItems) {
            this.bitItems = bitItems;
            var offset = 0;
            var maxBitLen = 32;
            var maxValue = 0xffffffff;
            for (let bitItem of this.bitItems) {
                bitItem.shift = offset; //动态计算
                bitItem.valueMask = maxValue >>> (maxBitLen - bitItem.fieldWidth); //动态计算
                bitItem.mask = (bitItem.valueMask << offset) >>> 0; //动态计算
                offset += bitItem.fieldWidth;
                Object.defineProperty(this, bitItem.name, {
                    get: function () {
                        return this.getBitField(bitItem) & bitItem.valueMask;
                    },
                    set: function (val) {
                        this.setBitField(bitItem, val);
                    },
                });
            }
        }
    }
    getBitField(bitItem) {
        return (this.all & bitItem.mask) >>> bitItem.shift;
    }
    setBitField(bitItem, val) {
        this.all = (this.all & ~bitItem.mask) | ((val & bitItem.valueMask) << bitItem.shift);
    }
} //end export class BitFields
/******************位域参数****************/
export class SeparatorCfg extends BitFields {
    constructor() {
        super([new BitItem("layout_vertical_align_", 2), new BitItem("independent_line_", 1)]);
    }
}
export class QCCfg extends BitFields {
    constructor() {
        super([
            new BitItem("forbid_empty_", 1),
            new BitItem("use_binary_len_", 1),
            new BitItem("use_integer_", 1),
            new BitItem("use_decimal_count_", 1),
            new BitItem("only_time_", 1),
            new BitItem("use_min_limit_", 1),
            new BitItem("use_max_limit_", 1),
        ]);
    }
}
export class SearchReplaceCfg extends BitFields {
    constructor() {
        super([new BitItem("forward_", 1), new BitItem("case_insensitive_", 1), new BitItem("include_element_hint_", 1), new BitItem("only_inner_", 1)]);
    }
}
export class ElementCfg extends BitFields {
    constructor() {
        super([
            new BitItem("dynamic_load_", 1),
            new BitItem("write_back_", 1),
            new BitItem("hide_key_word_", 2),
            new BitItem("horizontal_align_mode_", 3),
            new BitItem("under_line_", 1),
            new BitItem("multiple_choice_", 1),
            new BitItem("group_exclusion_", 1),
            new BitItem("sort_by_time_", 1),
            new BitItem("content_fit_min_width_", 1),
        ]);
    }
}
export class CheckBoxCfg extends BitFields {
    constructor() {
        super([
            new BitItem("is_checked_", 1),
            new BitItem("dynamic_load_", 1),
            new BitItem("reserve_0_", 2),
            new BitItem("check_figure_style_", 4),
            new BitItem("multiple_choice_", 1),
            new BitItem("group_exclusion_", 1),
        ]);
    }
}
export class BarCodeCfg extends BitFields {
    constructor() {
        super([new BitItem("type_", 8), new BitItem("show_hrt_", 1), new BitItem("dynamic_load_", 1), new BitItem("write_back_", 1)]);
    }
}
export class AttachCfg extends BitFields {
    constructor() {
        super([
            new BitItem("layer_", 1),
            new BitItem("type_", 4),
            new BitItem("source_", 2),
            new BitItem("forbid_print_", 1),
            new BitItem("texture_type_", 5),
            new BitItem("repeat_", 1),
        ]);
    }
}
export class EditBehaviorCfg extends BitFields {
    constructor() {
        super([new BitItem("check_doc_id_", 1), new BitItem("check_visit_id_", 1)]);
    }
}
/******************枚举 begin****************/
export var E_PRINT_SCOPE_MODE;
(function (E_PRINT_SCOPE_MODE) {
    E_PRINT_SCOPE_MODE[E_PRINT_SCOPE_MODE["All"] = 0] = "All";
    E_PRINT_SCOPE_MODE[E_PRINT_SCOPE_MODE["OddNumbers"] = 1] = "OddNumbers";
    E_PRINT_SCOPE_MODE[E_PRINT_SCOPE_MODE["EvenNumbers"] = 2] = "EvenNumbers";
    E_PRINT_SCOPE_MODE[E_PRINT_SCOPE_MODE["PagesRange"] = 3] = "PagesRange";
})(E_PRINT_SCOPE_MODE || (E_PRINT_SCOPE_MODE = {}));
export var E_SYSTEM_AUTH_MODE;
(function (E_SYSTEM_AUTH_MODE) {
    E_SYSTEM_AUTH_MODE[E_SYSTEM_AUTH_MODE["Demonstration"] = 0] = "Demonstration";
    E_SYSTEM_AUTH_MODE[E_SYSTEM_AUTH_MODE["Register"] = 1] = "Register";
    //WatermarkAuth,
    //UnWatermarkAuth,
})(E_SYSTEM_AUTH_MODE || (E_SYSTEM_AUTH_MODE = {}));
export var E_MESSAGE_LEVEL;
(function (E_MESSAGE_LEVEL) {
    E_MESSAGE_LEVEL[E_MESSAGE_LEVEL["Error"] = 0] = "Error";
    E_MESSAGE_LEVEL[E_MESSAGE_LEVEL["Warn"] = 1] = "Warn";
    E_MESSAGE_LEVEL[E_MESSAGE_LEVEL["Notice"] = 2] = "Notice";
})(E_MESSAGE_LEVEL || (E_MESSAGE_LEVEL = {}));
export var E_QC_CLASS;
(function (E_QC_CLASS) {
    E_QC_CLASS[E_QC_CLASS["String"] = 0] = "String";
    E_QC_CLASS[E_QC_CLASS["Number"] = 1] = "Number";
    E_QC_CLASS[E_QC_CLASS["DateTime"] = 2] = "DateTime";
    E_QC_CLASS[E_QC_CLASS["RegularExpression"] = 3] = "RegularExpression";
})(E_QC_CLASS || (E_QC_CLASS = {}));
export var E_SPLIT_PARAGRAPH_MODE;
(function (E_SPLIT_PARAGRAPH_MODE) {
    E_SPLIT_PARAGRAPH_MODE[E_SPLIT_PARAGRAPH_MODE["FollowParent"] = 0] = "FollowParent";
    E_SPLIT_PARAGRAPH_MODE[E_SPLIT_PARAGRAPH_MODE["BreakLine0SplitParagraph0"] = 1] = "BreakLine0SplitParagraph0";
    E_SPLIT_PARAGRAPH_MODE[E_SPLIT_PARAGRAPH_MODE["BreakLine1SplitParagraph0"] = 2] = "BreakLine1SplitParagraph0";
    E_SPLIT_PARAGRAPH_MODE[E_SPLIT_PARAGRAPH_MODE["BreakLine1SplitParagraph1"] = 3] = "BreakLine1SplitParagraph1";
})(E_SPLIT_PARAGRAPH_MODE || (E_SPLIT_PARAGRAPH_MODE = {}));
export var E_LINE_SPACE_RULE;
(function (E_LINE_SPACE_RULE) {
    E_LINE_SPACE_RULE[E_LINE_SPACE_RULE["LineSpaceSingle"] = 0] = "LineSpaceSingle";
    E_LINE_SPACE_RULE[E_LINE_SPACE_RULE["LineSpace1pt5"] = 1] = "LineSpace1pt5";
    E_LINE_SPACE_RULE[E_LINE_SPACE_RULE["LineSpaceDouble"] = 2] = "LineSpaceDouble";
    E_LINE_SPACE_RULE[E_LINE_SPACE_RULE["LineSpaceAtLeast"] = 3] = "LineSpaceAtLeast";
    E_LINE_SPACE_RULE[E_LINE_SPACE_RULE["LineSpaceExactly"] = 4] = "LineSpaceExactly";
    E_LINE_SPACE_RULE[E_LINE_SPACE_RULE["LineSpaceMultiple"] = 5] = "LineSpaceMultiple";
})(E_LINE_SPACE_RULE || (E_LINE_SPACE_RULE = {}));
export var E_LIST_LEVEL;
(function (E_LIST_LEVEL) {
    E_LIST_LEVEL[E_LIST_LEVEL["Normal"] = 0] = "Normal";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_1"] = 1] = "Level_1";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_2"] = 2] = "Level_2";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_3"] = 3] = "Level_3";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_4"] = 4] = "Level_4";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_5"] = 5] = "Level_5";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_6"] = 6] = "Level_6";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_7"] = 7] = "Level_7";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_8"] = 8] = "Level_8";
    E_LIST_LEVEL[E_LIST_LEVEL["Level_9"] = 9] = "Level_9";
})(E_LIST_LEVEL || (E_LIST_LEVEL = {}));
export var E_SPLIT_PARAGRAH_MODE;
(function (E_SPLIT_PARAGRAH_MODE) {
    E_SPLIT_PARAGRAH_MODE[E_SPLIT_PARAGRAH_MODE["FollowParent"] = 0] = "FollowParent";
    E_SPLIT_PARAGRAH_MODE[E_SPLIT_PARAGRAH_MODE["BreakLine0SplitParagrah0"] = 1] = "BreakLine0SplitParagrah0";
    E_SPLIT_PARAGRAH_MODE[E_SPLIT_PARAGRAH_MODE["BreakLine1SplitParagrah0"] = 2] = "BreakLine1SplitParagrah0";
    E_SPLIT_PARAGRAH_MODE[E_SPLIT_PARAGRAH_MODE["BreakLine1SplitParagrah1"] = 3] = "BreakLine1SplitParagrah1";
})(E_SPLIT_PARAGRAH_MODE || (E_SPLIT_PARAGRAH_MODE = {}));
export var E_ATTACH_LAYER;
(function (E_ATTACH_LAYER) {
    E_ATTACH_LAYER[E_ATTACH_LAYER["Lower"] = 0] = "Lower";
    E_ATTACH_LAYER[E_ATTACH_LAYER["Upper"] = 1] = "Upper";
})(E_ATTACH_LAYER || (E_ATTACH_LAYER = {}));
export var E_ATTACH_TYPE;
(function (E_ATTACH_TYPE) {
    E_ATTACH_TYPE[E_ATTACH_TYPE["Background"] = 0] = "Background";
    E_ATTACH_TYPE[E_ATTACH_TYPE["WaterMark"] = 1] = "WaterMark";
    E_ATTACH_TYPE[E_ATTACH_TYPE["Lines"] = 2] = "Lines";
    E_ATTACH_TYPE[E_ATTACH_TYPE["Rectangle"] = 3] = "Rectangle";
    E_ATTACH_TYPE[E_ATTACH_TYPE["Polygon"] = 4] = "Polygon";
    E_ATTACH_TYPE[E_ATTACH_TYPE["Ellipse"] = 5] = "Ellipse";
    E_ATTACH_TYPE[E_ATTACH_TYPE["LinkBox"] = 6] = "LinkBox";
    E_ATTACH_TYPE[E_ATTACH_TYPE["Magnifier"] = 7] = "Magnifier";
})(E_ATTACH_TYPE || (E_ATTACH_TYPE = {}));
export var E_ATTACH_SOURCE;
(function (E_ATTACH_SOURCE) {
    E_ATTACH_SOURCE[E_ATTACH_SOURCE["User"] = 0] = "User";
    E_ATTACH_SOURCE[E_ATTACH_SOURCE["SystemAuth"] = 1] = "SystemAuth";
    E_ATTACH_SOURCE[E_ATTACH_SOURCE["SystemDynamic"] = 2] = "SystemDynamic";
})(E_ATTACH_SOURCE || (E_ATTACH_SOURCE = {}));
export var E_TEXTURE_TYPE;
(function (E_TEXTURE_TYPE) {
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Null"] = 0] = "Null";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type1"] = 1] = "Type1";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type2"] = 2] = "Type2";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type3"] = 3] = "Type3";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type4"] = 4] = "Type4";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type5"] = 5] = "Type5";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type6"] = 6] = "Type6";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type7"] = 7] = "Type7";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type8"] = 8] = "Type8";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type9"] = 9] = "Type9";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type10"] = 10] = "Type10";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type11"] = 11] = "Type11";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type12"] = 12] = "Type12";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type13"] = 13] = "Type13";
    E_TEXTURE_TYPE[E_TEXTURE_TYPE["Type14"] = 14] = "Type14";
})(E_TEXTURE_TYPE || (E_TEXTURE_TYPE = {}));
export var E_WATER_MARK_TYPE;
(function (E_WATER_MARK_TYPE) {
    E_WATER_MARK_TYPE[E_WATER_MARK_TYPE["Text"] = 0] = "Text";
    E_WATER_MARK_TYPE[E_WATER_MARK_TYPE["Image"] = 1] = "Image";
})(E_WATER_MARK_TYPE || (E_WATER_MARK_TYPE = {}));
export var E_BORDER_STYLE;
(function (E_BORDER_STYLE) {
    E_BORDER_STYLE[E_BORDER_STYLE["NULL"] = 0] = "NULL";
    E_BORDER_STYLE[E_BORDER_STYLE["SOLID"] = 1] = "SOLID";
})(E_BORDER_STYLE || (E_BORDER_STYLE = {}));
export var E_PAGE_NUM_SCOPE;
(function (E_PAGE_NUM_SCOPE) {
    E_PAGE_NUM_SCOPE[E_PAGE_NUM_SCOPE["WholeDocnment"] = 0] = "WholeDocnment";
    E_PAGE_NUM_SCOPE[E_PAGE_NUM_SCOPE["InnerSection"] = 1] = "InnerSection";
})(E_PAGE_NUM_SCOPE || (E_PAGE_NUM_SCOPE = {}));
export var E_PAGE_NUM_STYLE;
(function (E_PAGE_NUM_STYLE) {
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_0"] = 0] = "OrderListModel_0";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_1"] = 1] = "OrderListModel_1";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_2"] = 2] = "OrderListModel_2";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_3"] = 3] = "OrderListModel_3";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_4"] = 4] = "OrderListModel_4";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_5"] = 5] = "OrderListModel_5";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_6"] = 6] = "OrderListModel_6";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_7"] = 7] = "OrderListModel_7";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_8"] = 8] = "OrderListModel_8";
    E_PAGE_NUM_STYLE[E_PAGE_NUM_STYLE["OrderListModel_9"] = 9] = "OrderListModel_9";
})(E_PAGE_NUM_STYLE || (E_PAGE_NUM_STYLE = {}));
export var E_PAGE_NUM_FORMAT;
(function (E_PAGE_NUM_FORMAT) {
    E_PAGE_NUM_FORMAT[E_PAGE_NUM_FORMAT["PageOrder"] = 0] = "PageOrder";
    E_PAGE_NUM_FORMAT[E_PAGE_NUM_FORMAT["PagesCount"] = 1] = "PagesCount";
    E_PAGE_NUM_FORMAT[E_PAGE_NUM_FORMAT["PageOrderFormat"] = 2] = "PageOrderFormat";
    E_PAGE_NUM_FORMAT[E_PAGE_NUM_FORMAT["PagesCountFormat"] = 3] = "PagesCountFormat";
    E_PAGE_NUM_FORMAT[E_PAGE_NUM_FORMAT["PageOrderPagesCountFormat"] = 4] = "PageOrderPagesCountFormat";
})(E_PAGE_NUM_FORMAT || (E_PAGE_NUM_FORMAT = {}));
export var E_CHECK_FIGURE_STYLE;
(function (E_CHECK_FIGURE_STYLE) {
    E_CHECK_FIGURE_STYLE[E_CHECK_FIGURE_STYLE["Style_0"] = 0] = "Style_0";
    E_CHECK_FIGURE_STYLE[E_CHECK_FIGURE_STYLE["Style_1"] = 1] = "Style_1";
    E_CHECK_FIGURE_STYLE[E_CHECK_FIGURE_STYLE["Style_2"] = 2] = "Style_2";
    E_CHECK_FIGURE_STYLE[E_CHECK_FIGURE_STYLE["Style_3"] = 3] = "Style_3";
    E_CHECK_FIGURE_STYLE[E_CHECK_FIGURE_STYLE["Style_4"] = 4] = "Style_4";
})(E_CHECK_FIGURE_STYLE || (E_CHECK_FIGURE_STYLE = {}));
export var E_LOG_LEVEL;
(function (E_LOG_LEVEL) {
    E_LOG_LEVEL[E_LOG_LEVEL["Error"] = 0] = "Error";
    E_LOG_LEVEL[E_LOG_LEVEL["Warn"] = 1] = "Warn";
    E_LOG_LEVEL[E_LOG_LEVEL["Info"] = 2] = "Info";
    E_LOG_LEVEL[E_LOG_LEVEL["Debug"] = 3] = "Debug";
    //DebugEvent,
    // DebugFlush,
    // DebugLayout, //段落、EditPanel产生行
    // DebugEleSet,
    // DebugMouseMove,
    E_LOG_LEVEL[E_LOG_LEVEL["All"] = 4] = "All";
})(E_LOG_LEVEL || (E_LOG_LEVEL = {}));
export var E_BARCODE_TYPE;
(function (E_BARCODE_TYPE) {
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE11"] = 1] = "CODE11";
    E_BARCODE_TYPE[E_BARCODE_TYPE["C25MATRIX"] = 2] = "C25MATRIX";
    E_BARCODE_TYPE[E_BARCODE_TYPE["C25INTER"] = 3] = "C25INTER";
    E_BARCODE_TYPE[E_BARCODE_TYPE["C25IATA"] = 4] = "C25IATA";
    E_BARCODE_TYPE[E_BARCODE_TYPE["C25LOGIC"] = 5] = "C25LOGIC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["C25IND"] = 6] = "C25IND";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE39"] = 7] = "CODE39";
    E_BARCODE_TYPE[E_BARCODE_TYPE["EXCODE39"] = 8] = "EXCODE39";
    E_BARCODE_TYPE[E_BARCODE_TYPE["EANX"] = 13] = "EANX";
    E_BARCODE_TYPE[E_BARCODE_TYPE["EAN128"] = 16] = "EAN128";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODABAR"] = 18] = "CODABAR";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE128"] = 20] = "CODE128";
    E_BARCODE_TYPE[E_BARCODE_TYPE["DPLEIT"] = 21] = "DPLEIT";
    E_BARCODE_TYPE[E_BARCODE_TYPE["DPIDENT"] = 22] = "DPIDENT";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE16K"] = 23] = "CODE16K";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE49"] = 24] = "CODE49";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE93"] = 25] = "CODE93";
    E_BARCODE_TYPE[E_BARCODE_TYPE["FLAT"] = 28] = "FLAT";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS14"] = 29] = "RSS14";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS_LTD"] = 30] = "RSS_LTD";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS_EXP"] = 31] = "RSS_EXP";
    E_BARCODE_TYPE[E_BARCODE_TYPE["TELEPEN"] = 32] = "TELEPEN";
    E_BARCODE_TYPE[E_BARCODE_TYPE["UPCA"] = 34] = "UPCA";
    E_BARCODE_TYPE[E_BARCODE_TYPE["UPCE"] = 37] = "UPCE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["POSTNET"] = 40] = "POSTNET";
    E_BARCODE_TYPE[E_BARCODE_TYPE["MSI_PLESSEY"] = 47] = "MSI_PLESSEY";
    E_BARCODE_TYPE[E_BARCODE_TYPE["FIM"] = 49] = "FIM";
    E_BARCODE_TYPE[E_BARCODE_TYPE["LOGMARS"] = 50] = "LOGMARS";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PHARMA"] = 51] = "PHARMA";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PZN"] = 52] = "PZN";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PHARMA_TWO"] = 53] = "PHARMA_TWO";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PDF417"] = 55] = "PDF417";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PDF417TRUNC"] = 56] = "PDF417TRUNC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["MAXICODE"] = 57] = "MAXICODE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["QRCODE"] = 58] = "QRCODE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE128B"] = 60] = "CODE128B";
    E_BARCODE_TYPE[E_BARCODE_TYPE["AUSPOST"] = 63] = "AUSPOST";
    E_BARCODE_TYPE[E_BARCODE_TYPE["AUSREPLY"] = 66] = "AUSREPLY";
    E_BARCODE_TYPE[E_BARCODE_TYPE["AUSROUTE"] = 67] = "AUSROUTE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["AUSREDIRECT"] = 68] = "AUSREDIRECT";
    E_BARCODE_TYPE[E_BARCODE_TYPE["ISBNX"] = 69] = "ISBNX";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RM4SCC"] = 70] = "RM4SCC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["DATAMATRIX"] = 71] = "DATAMATRIX";
    E_BARCODE_TYPE[E_BARCODE_TYPE["EAN14"] = 72] = "EAN14";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODABLOCKF"] = 74] = "CODABLOCKF";
    E_BARCODE_TYPE[E_BARCODE_TYPE["NVE18"] = 75] = "NVE18";
    E_BARCODE_TYPE[E_BARCODE_TYPE["JAPANPOST"] = 76] = "JAPANPOST";
    E_BARCODE_TYPE[E_BARCODE_TYPE["KOREAPOST"] = 77] = "KOREAPOST";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS14STACK"] = 79] = "RSS14STACK";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS14STACK_OMNI"] = 80] = "RSS14STACK_OMNI";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS_EXPSTACK"] = 81] = "RSS_EXPSTACK";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PLANET"] = 82] = "PLANET";
    E_BARCODE_TYPE[E_BARCODE_TYPE["MICROPDF417"] = 84] = "MICROPDF417";
    E_BARCODE_TYPE[E_BARCODE_TYPE["ONECODE"] = 85] = "ONECODE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["PLESSEY"] = 86] = "PLESSEY";
    E_BARCODE_TYPE[E_BARCODE_TYPE["TELEPEN_NUM"] = 87] = "TELEPEN_NUM";
    E_BARCODE_TYPE[E_BARCODE_TYPE["ITF14"] = 89] = "ITF14";
    E_BARCODE_TYPE[E_BARCODE_TYPE["KIX"] = 90] = "KIX";
    E_BARCODE_TYPE[E_BARCODE_TYPE["AZTEC"] = 92] = "AZTEC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["DAFT"] = 93] = "DAFT";
    E_BARCODE_TYPE[E_BARCODE_TYPE["MICROQR"] = 97] = "MICROQR";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_128"] = 98] = "HIBC_128";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_39"] = 99] = "HIBC_39";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_DM"] = 102] = "HIBC_DM";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_QR"] = 104] = "HIBC_QR";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_PDF"] = 106] = "HIBC_PDF";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_MICPDF"] = 108] = "HIBC_MICPDF";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_BLOCKF"] = 110] = "HIBC_BLOCKF";
    E_BARCODE_TYPE[E_BARCODE_TYPE["HIBC_AZTEC"] = 112] = "HIBC_AZTEC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["AZRUNE"] = 128] = "AZRUNE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODE32"] = 129] = "CODE32";
    E_BARCODE_TYPE[E_BARCODE_TYPE["EANX_CC"] = 130] = "EANX_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["EAN128_CC"] = 131] = "EAN128_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS14_CC"] = 132] = "RSS14_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS_LTD_CC"] = 133] = "RSS_LTD_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS_EXP_CC"] = 134] = "RSS_EXP_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["UPCA_CC"] = 135] = "UPCA_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["UPCE_CC"] = 136] = "UPCE_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS14STACK_CC"] = 137] = "RSS14STACK_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS14_OMNI_CC"] = 138] = "RSS14_OMNI_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["RSS_EXPSTACK_CC"] = 139] = "RSS_EXPSTACK_CC";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CHANNEL"] = 140] = "CHANNEL";
    E_BARCODE_TYPE[E_BARCODE_TYPE["CODEONE"] = 141] = "CODEONE";
    E_BARCODE_TYPE[E_BARCODE_TYPE["GRIDMATRIX"] = 142] = "GRIDMATRIX";
})(E_BARCODE_TYPE || (E_BARCODE_TYPE = {}));
export var E_FORMULA_STYLE;
(function (E_FORMULA_STYLE) {
    E_FORMULA_STYLE[E_FORMULA_STYLE["Common"] = 0] = "Common";
    E_FORMULA_STYLE[E_FORMULA_STYLE["MensesStyle_1"] = 1] = "MensesStyle_1";
    E_FORMULA_STYLE[E_FORMULA_STYLE["MensesStyle_2"] = 2] = "MensesStyle_2";
    E_FORMULA_STYLE[E_FORMULA_STYLE["MensesStyle_3"] = 3] = "MensesStyle_3";
    E_FORMULA_STYLE[E_FORMULA_STYLE["MensesStyle_4"] = 4] = "MensesStyle_4";
    E_FORMULA_STYLE[E_FORMULA_STYLE["PupilPosition"] = 5] = "PupilPosition";
    E_FORMULA_STYLE[E_FORMULA_STYLE["LightProjection"] = 6] = "LightProjection";
    E_FORMULA_STYLE[E_FORMULA_STYLE["FetalHeart"] = 7] = "FetalHeart";
    E_FORMULA_STYLE[E_FORMULA_STYLE["PermanentTeethPos"] = 8] = "PermanentTeethPos";
    E_FORMULA_STYLE[E_FORMULA_STYLE["DeciduousTeethPos"] = 9] = "DeciduousTeethPos";
})(E_FORMULA_STYLE || (E_FORMULA_STYLE = {}));
export var E_TIME_TYPE;
(function (E_TIME_TYPE) {
    E_TIME_TYPE[E_TIME_TYPE["Date"] = 0] = "Date";
    E_TIME_TYPE[E_TIME_TYPE["DateTime"] = 1] = "DateTime";
    E_TIME_TYPE[E_TIME_TYPE["DateTimeNoSecond"] = 2] = "DateTimeNoSecond";
    E_TIME_TYPE[E_TIME_TYPE["Time"] = 3] = "Time";
    E_TIME_TYPE[E_TIME_TYPE["TimeNoSecond"] = 4] = "TimeNoSecond";
})(E_TIME_TYPE || (E_TIME_TYPE = {}));
export var E_INPUT_MODE;
(function (E_INPUT_MODE) {
    E_INPUT_MODE[E_INPUT_MODE["InputText"] = 0] = "InputText";
    E_INPUT_MODE[E_INPUT_MODE["InputNumber"] = 1] = "InputNumber";
    E_INPUT_MODE[E_INPUT_MODE["SelectOption"] = 2] = "SelectOption";
    E_INPUT_MODE[E_INPUT_MODE["SelectDateTime"] = 3] = "SelectDateTime";
})(E_INPUT_MODE || (E_INPUT_MODE = {}));
export var E_UPDATE_PARAGRAPH_CFG_MODE;
(function (E_UPDATE_PARAGRAPH_CFG_MODE) {
    E_UPDATE_PARAGRAPH_CFG_MODE[E_UPDATE_PARAGRAPH_CFG_MODE["ForEmptyContent"] = 0] = "ForEmptyContent";
    E_UPDATE_PARAGRAPH_CFG_MODE[E_UPDATE_PARAGRAPH_CFG_MODE["ForEmptyContentAndIdentity"] = 1] = "ForEmptyContentAndIdentity";
    E_UPDATE_PARAGRAPH_CFG_MODE[E_UPDATE_PARAGRAPH_CFG_MODE["ForceUpdate"] = 2] = "ForceUpdate";
    E_UPDATE_PARAGRAPH_CFG_MODE[E_UPDATE_PARAGRAPH_CFG_MODE["ForceNoUpdate"] = 3] = "ForceNoUpdate";
})(E_UPDATE_PARAGRAPH_CFG_MODE || (E_UPDATE_PARAGRAPH_CFG_MODE = {}));
export var E_IDENTITY_OBJECT_TYPE;
(function (E_IDENTITY_OBJECT_TYPE) {
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["Unknown"] = 0] = "Unknown";
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["Paragraph"] = 1] = "Paragraph";
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["Element"] = 2] = "Element";
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["Image"] = 3] = "Image";
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["Table"] = 4] = "Table";
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["CheckBox"] = 5] = "CheckBox";
    E_IDENTITY_OBJECT_TYPE[E_IDENTITY_OBJECT_TYPE["Formula"] = 6] = "Formula";
})(E_IDENTITY_OBJECT_TYPE || (E_IDENTITY_OBJECT_TYPE = {}));
export var E_DOC_FORMAT;
(function (E_DOC_FORMAT) {
    E_DOC_FORMAT[E_DOC_FORMAT["Unknown"] = 0] = "Unknown";
    E_DOC_FORMAT[E_DOC_FORMAT["TEXT"] = 1] = "TEXT";
    E_DOC_FORMAT[E_DOC_FORMAT["XML"] = 2] = "XML";
    E_DOC_FORMAT[E_DOC_FORMAT["HTML"] = 3] = "HTML";
    E_DOC_FORMAT[E_DOC_FORMAT["Content"] = 4] = "Content";
    E_DOC_FORMAT[E_DOC_FORMAT["TextEx"] = 5] = "TextEx";
})(E_DOC_FORMAT || (E_DOC_FORMAT = {}));
export var E_DOC_TYPE;
(function (E_DOC_TYPE) {
    E_DOC_TYPE[E_DOC_TYPE["Template"] = 0] = "Template";
    E_DOC_TYPE[E_DOC_TYPE["Entity"] = 1] = "Entity";
    E_DOC_TYPE[E_DOC_TYPE["Fragment"] = 2] = "Fragment";
    E_DOC_TYPE[E_DOC_TYPE["Knowledge"] = 3] = "Knowledge";
})(E_DOC_TYPE || (E_DOC_TYPE = {}));
export var E_DOCS_ORGANIZE_MODE;
(function (E_DOCS_ORGANIZE_MODE) {
    E_DOCS_ORGANIZE_MODE[E_DOCS_ORGANIZE_MODE["Single"] = 0] = "Single";
    E_DOCS_ORGANIZE_MODE[E_DOCS_ORGANIZE_MODE["MergeContent"] = 1] = "MergeContent";
    E_DOCS_ORGANIZE_MODE[E_DOCS_ORGANIZE_MODE["UnionContent"] = 2] = "UnionContent";
    E_DOCS_ORGANIZE_MODE[E_DOCS_ORGANIZE_MODE["UnionSection"] = 3] = "UnionSection";
})(E_DOCS_ORGANIZE_MODE || (E_DOCS_ORGANIZE_MODE = {}));
export var E_PAGES_LAYOUT_MODE;
(function (E_PAGES_LAYOUT_MODE) {
    E_PAGES_LAYOUT_MODE[E_PAGES_LAYOUT_MODE["SinglePage"] = 0] = "SinglePage";
    E_PAGES_LAYOUT_MODE[E_PAGES_LAYOUT_MODE["Ratio"] = 1] = "Ratio";
    E_PAGES_LAYOUT_MODE[E_PAGES_LAYOUT_MODE["MultiPages"] = 2] = "MultiPages";
    E_PAGES_LAYOUT_MODE[E_PAGES_LAYOUT_MODE["FillViewWidth"] = 3] = "FillViewWidth";
})(E_PAGES_LAYOUT_MODE || (E_PAGES_LAYOUT_MODE = {}));
export var E_VIEW_MODE;
(function (E_VIEW_MODE) {
    E_VIEW_MODE[E_VIEW_MODE["Edit"] = 0] = "Edit";
    E_VIEW_MODE[E_VIEW_MODE["Browse"] = 1] = "Browse";
    E_VIEW_MODE[E_VIEW_MODE["Print"] = 2] = "Print";
    E_VIEW_MODE[E_VIEW_MODE["SelectPagePrint"] = 3] = "SelectPagePrint";
    E_VIEW_MODE[E_VIEW_MODE["SelectContentPrint"] = 4] = "SelectContentPrint";
    E_VIEW_MODE[E_VIEW_MODE["ContinuePrint"] = 5] = "ContinuePrint";
})(E_VIEW_MODE || (E_VIEW_MODE = {}));
export var E_IMAGE_TYPE;
(function (E_IMAGE_TYPE) {
    E_IMAGE_TYPE[E_IMAGE_TYPE["ERROR"] = 0] = "ERROR";
    E_IMAGE_TYPE[E_IMAGE_TYPE["ICO"] = 1] = "ICO";
    E_IMAGE_TYPE[E_IMAGE_TYPE["CUR"] = 2] = "CUR";
    E_IMAGE_TYPE[E_IMAGE_TYPE["BMP"] = 3] = "BMP";
    E_IMAGE_TYPE[E_IMAGE_TYPE["GIF"] = 4] = "GIF";
    E_IMAGE_TYPE[E_IMAGE_TYPE["JPG"] = 5] = "JPG";
    E_IMAGE_TYPE[E_IMAGE_TYPE["LBM"] = 6] = "LBM";
    E_IMAGE_TYPE[E_IMAGE_TYPE["PCX"] = 7] = "PCX";
    E_IMAGE_TYPE[E_IMAGE_TYPE["PNG"] = 8] = "PNG";
    E_IMAGE_TYPE[E_IMAGE_TYPE["PNM"] = 9] = "PNM";
    E_IMAGE_TYPE[E_IMAGE_TYPE["SVG"] = 10] = "SVG";
    E_IMAGE_TYPE[E_IMAGE_TYPE["TIF"] = 11] = "TIF";
    E_IMAGE_TYPE[E_IMAGE_TYPE["XCF"] = 12] = "XCF";
    E_IMAGE_TYPE[E_IMAGE_TYPE["XPM"] = 13] = "XPM";
    E_IMAGE_TYPE[E_IMAGE_TYPE["XV"] = 14] = "XV";
    E_IMAGE_TYPE[E_IMAGE_TYPE["WEBP"] = 15] = "WEBP";
})(E_IMAGE_TYPE || (E_IMAGE_TYPE = {}));
export var E_DISPLAY_MODE;
(function (E_DISPLAY_MODE) {
    E_DISPLAY_MODE[E_DISPLAY_MODE["Hide"] = 0] = "Hide";
    E_DISPLAY_MODE[E_DISPLAY_MODE["Show"] = 1] = "Show";
})(E_DISPLAY_MODE || (E_DISPLAY_MODE = {}));
export var E_SET_MODE;
(function (E_SET_MODE) {
    E_SET_MODE[E_SET_MODE["InsertCommon"] = 0] = "InsertCommon";
    E_SET_MODE[E_SET_MODE["ModifyCommon"] = 1] = "ModifyCommon";
    E_SET_MODE[E_SET_MODE["InsertSpecial"] = 2] = "InsertSpecial";
    E_SET_MODE[E_SET_MODE["ModifySpecial"] = 3] = "ModifySpecial";
})(E_SET_MODE || (E_SET_MODE = {}));
export var E_BRUSH_STATE;
(function (E_BRUSH_STATE) {
    E_BRUSH_STATE[E_BRUSH_STATE["BrushNull"] = 0] = "BrushNull";
    E_BRUSH_STATE[E_BRUSH_STATE["BrushOnce"] = 1] = "BrushOnce";
    E_BRUSH_STATE[E_BRUSH_STATE["BrushHold"] = 2] = "BrushHold";
})(E_BRUSH_STATE || (E_BRUSH_STATE = {}));
export var E_PASTE_TYPE;
(function (E_PASTE_TYPE) {
    E_PASTE_TYPE[E_PASTE_TYPE["Normal"] = 0] = "Normal";
    E_PASTE_TYPE[E_PASTE_TYPE["OnlyText"] = 1] = "OnlyText";
    E_PASTE_TYPE[E_PASTE_TYPE["OnlyXml"] = 2] = "OnlyXml";
})(E_PASTE_TYPE || (E_PASTE_TYPE = {}));
export var E_PASTE_BEHAVIOR_MODE;
(function (E_PASTE_BEHAVIOR_MODE) {
    E_PASTE_BEHAVIOR_MODE[E_PASTE_BEHAVIOR_MODE["NoCheck"] = 0] = "NoCheck";
    E_PASTE_BEHAVIOR_MODE[E_PASTE_BEHAVIOR_MODE["ForbidNeedNotify"] = 1] = "ForbidNeedNotify";
    E_PASTE_BEHAVIOR_MODE[E_PASTE_BEHAVIOR_MODE["ForbidNoNotify"] = 2] = "ForbidNoNotify";
    E_PASTE_BEHAVIOR_MODE[E_PASTE_BEHAVIOR_MODE["UserChoose"] = 3] = "UserChoose";
})(E_PASTE_BEHAVIOR_MODE || (E_PASTE_BEHAVIOR_MODE = {}));
export var E_SCRIPT_MODE;
(function (E_SCRIPT_MODE) {
    E_SCRIPT_MODE[E_SCRIPT_MODE["Normal"] = 0] = "Normal";
    E_SCRIPT_MODE[E_SCRIPT_MODE["SuperScript"] = 1] = "SuperScript";
    E_SCRIPT_MODE[E_SCRIPT_MODE["SubScript"] = 2] = "SubScript";
})(E_SCRIPT_MODE || (E_SCRIPT_MODE = {}));
export var E_FERRULE_MODE;
(function (E_FERRULE_MODE) {
    E_FERRULE_MODE[E_FERRULE_MODE["Normal"] = 0] = "Normal";
    E_FERRULE_MODE[E_FERRULE_MODE["Circle"] = 1] = "Circle";
    E_FERRULE_MODE[E_FERRULE_MODE["Rectangle"] = 2] = "Rectangle";
})(E_FERRULE_MODE || (E_FERRULE_MODE = {}));
export var E_SHOW_BG_MODE;
(function (E_SHOW_BG_MODE) {
    E_SHOW_BG_MODE[E_SHOW_BG_MODE["AlwaysHide"] = 0] = "AlwaysHide";
    E_SHOW_BG_MODE[E_SHOW_BG_MODE["AlwaysShow"] = 1] = "AlwaysShow";
    E_SHOW_BG_MODE[E_SHOW_BG_MODE["ShowBySelected"] = 2] = "ShowBySelected";
})(E_SHOW_BG_MODE || (E_SHOW_BG_MODE = {}));
export var E_LIST_MODE;
(function (E_LIST_MODE) {
    E_LIST_MODE[E_LIST_MODE["Normal"] = 0] = "Normal";
    //SymbolList_Start: {value:1, writable:false},
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_0"] = 1] = "SymbolListModel_0";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_1"] = 2] = "SymbolListModel_1";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_2"] = 3] = "SymbolListModel_2";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_3"] = 4] = "SymbolListModel_3";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_4"] = 5] = "SymbolListModel_4";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_5"] = 6] = "SymbolListModel_5";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_6"] = 7] = "SymbolListModel_6";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_7"] = 8] = "SymbolListModel_7";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_8"] = 9] = "SymbolListModel_8";
    E_LIST_MODE[E_LIST_MODE["SymbolListModel_9"] = 10] = "SymbolListModel_9";
    //SymbolList_End: {value:12, writable:false},
    //OrderList_Start: {value:13, writable:false}, //[不预留]需要保存到xml，所以预留范围
    E_LIST_MODE[E_LIST_MODE["OrderListModel_0"] = 11] = "OrderListModel_0";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_1"] = 12] = "OrderListModel_1";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_2"] = 13] = "OrderListModel_2";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_3"] = 14] = "OrderListModel_3";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_4"] = 15] = "OrderListModel_4";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_5"] = 16] = "OrderListModel_5";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_6"] = 17] = "OrderListModel_6";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_7"] = 18] = "OrderListModel_7";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_8"] = 19] = "OrderListModel_8";
    E_LIST_MODE[E_LIST_MODE["OrderListModel_9"] = 20] = "OrderListModel_9";
    //OrderList_End: {value:24, writable:false},
})(E_LIST_MODE || (E_LIST_MODE = {}));
export var E_COMBINE_STYLE;
(function (E_COMBINE_STYLE) {
    E_COMBINE_STYLE[E_COMBINE_STYLE["Common"] = 0] = "Common";
    E_COMBINE_STYLE[E_COMBINE_STYLE["Style1"] = 1] = "Style1";
})(E_COMBINE_STYLE || (E_COMBINE_STYLE = {}));
export var E_SPECIFIC_INDENT_FORMAT;
(function (E_SPECIFIC_INDENT_FORMAT) {
    E_SPECIFIC_INDENT_FORMAT[E_SPECIFIC_INDENT_FORMAT["NoIndent"] = 0] = "NoIndent";
    E_SPECIFIC_INDENT_FORMAT[E_SPECIFIC_INDENT_FORMAT["FristLine"] = 1] = "FristLine";
    E_SPECIFIC_INDENT_FORMAT[E_SPECIFIC_INDENT_FORMAT["Hanging"] = 2] = "Hanging";
})(E_SPECIFIC_INDENT_FORMAT || (E_SPECIFIC_INDENT_FORMAT = {}));
export var E_ALIGN_HORIZONTAL_MODE;
(function (E_ALIGN_HORIZONTAL_MODE) {
    E_ALIGN_HORIZONTAL_MODE[E_ALIGN_HORIZONTAL_MODE["Left"] = 0] = "Left";
    E_ALIGN_HORIZONTAL_MODE[E_ALIGN_HORIZONTAL_MODE["Center"] = 1] = "Center";
    E_ALIGN_HORIZONTAL_MODE[E_ALIGN_HORIZONTAL_MODE["Right"] = 2] = "Right";
    E_ALIGN_HORIZONTAL_MODE[E_ALIGN_HORIZONTAL_MODE["Justify"] = 3] = "Justify";
    E_ALIGN_HORIZONTAL_MODE[E_ALIGN_HORIZONTAL_MODE["Distribute"] = 4] = "Distribute";
})(E_ALIGN_HORIZONTAL_MODE || (E_ALIGN_HORIZONTAL_MODE = {}));
export var E_ALIGN_VERTICAL_MODE;
(function (E_ALIGN_VERTICAL_MODE) {
    E_ALIGN_VERTICAL_MODE[E_ALIGN_VERTICAL_MODE["Top"] = 0] = "Top";
    E_ALIGN_VERTICAL_MODE[E_ALIGN_VERTICAL_MODE["Middle"] = 1] = "Middle";
    E_ALIGN_VERTICAL_MODE[E_ALIGN_VERTICAL_MODE["Bottom"] = 2] = "Bottom";
})(E_ALIGN_VERTICAL_MODE || (E_ALIGN_VERTICAL_MODE = {}));
export var E_ALIGN_VERTICAL_LAYOUT;
(function (E_ALIGN_VERTICAL_LAYOUT) {
    //@20240512 用于逐渐替换E_LAYOUT_VERTICAL_ALIGN
    E_ALIGN_VERTICAL_LAYOUT[E_ALIGN_VERTICAL_LAYOUT["Bottom"] = 0] = "Bottom";
    E_ALIGN_VERTICAL_LAYOUT[E_ALIGN_VERTICAL_LAYOUT["SelfMiddle"] = 1] = "SelfMiddle";
    E_ALIGN_VERTICAL_LAYOUT[E_ALIGN_VERTICAL_LAYOUT["OtherMiddle"] = 2] = "OtherMiddle";
    E_ALIGN_VERTICAL_LAYOUT[E_ALIGN_VERTICAL_LAYOUT["TOP"] = 3] = "TOP";
})(E_ALIGN_VERTICAL_LAYOUT || (E_ALIGN_VERTICAL_LAYOUT = {}));
export var E_LAYOUT_VERTICAL_ALIGN;
(function (E_LAYOUT_VERTICAL_ALIGN) {
    E_LAYOUT_VERTICAL_ALIGN[E_LAYOUT_VERTICAL_ALIGN["Bottom"] = 0] = "Bottom";
    E_LAYOUT_VERTICAL_ALIGN[E_LAYOUT_VERTICAL_ALIGN["SelfMiddle"] = 1] = "SelfMiddle";
    E_LAYOUT_VERTICAL_ALIGN[E_LAYOUT_VERTICAL_ALIGN["OtherMiddle"] = 2] = "OtherMiddle";
    E_LAYOUT_VERTICAL_ALIGN[E_LAYOUT_VERTICAL_ALIGN["TOP"] = 3] = "TOP";
})(E_LAYOUT_VERTICAL_ALIGN || (E_LAYOUT_VERTICAL_ALIGN = {}));
export var E_TABLE_INSERT_MODE;
(function (E_TABLE_INSERT_MODE) {
    E_TABLE_INSERT_MODE[E_TABLE_INSERT_MODE["ColLeft"] = 0] = "ColLeft";
    E_TABLE_INSERT_MODE[E_TABLE_INSERT_MODE["ColRight"] = 1] = "ColRight";
    E_TABLE_INSERT_MODE[E_TABLE_INSERT_MODE["RowUp"] = 2] = "RowUp";
    E_TABLE_INSERT_MODE[E_TABLE_INSERT_MODE["RowDown"] = 3] = "RowDown";
})(E_TABLE_INSERT_MODE || (E_TABLE_INSERT_MODE = {}));
export var E_TABLE_DELETE_MODE;
(function (E_TABLE_DELETE_MODE) {
    E_TABLE_DELETE_MODE[E_TABLE_DELETE_MODE["Col"] = 0] = "Col";
    E_TABLE_DELETE_MODE[E_TABLE_DELETE_MODE["Row"] = 1] = "Row";
    E_TABLE_DELETE_MODE[E_TABLE_DELETE_MODE["Table"] = 2] = "Table";
})(E_TABLE_DELETE_MODE || (E_TABLE_DELETE_MODE = {}));
export var E_CELL_OPT_MODE;
(function (E_CELL_OPT_MODE) {
    E_CELL_OPT_MODE[E_CELL_OPT_MODE["Merge"] = 0] = "Merge";
    E_CELL_OPT_MODE[E_CELL_OPT_MODE["Split"] = 1] = "Split";
})(E_CELL_OPT_MODE || (E_CELL_OPT_MODE = {}));
export var E_ALGO_ROLE;
(function (E_ALGO_ROLE) {
    E_ALGO_ROLE[E_ALGO_ROLE["In"] = 0] = "In";
    E_ALGO_ROLE[E_ALGO_ROLE["Out"] = 1] = "Out";
})(E_ALGO_ROLE || (E_ALGO_ROLE = {}));
export var E_ALGO_MODE;
(function (E_ALGO_MODE) {
    E_ALGO_MODE[E_ALGO_MODE["Common"] = 0] = "Common";
    E_ALGO_MODE[E_ALGO_MODE["Addition"] = 1] = "Addition";
})(E_ALGO_MODE || (E_ALGO_MODE = {}));
export var E_ALGO_SCOPE;
(function (E_ALGO_SCOPE) {
    E_ALGO_SCOPE[E_ALGO_SCOPE["Doc"] = 0] = "Doc";
    E_ALGO_SCOPE[E_ALGO_SCOPE["Table"] = 1] = "Table";
    E_ALGO_SCOPE[E_ALGO_SCOPE["Row"] = 2] = "Row";
})(E_ALGO_SCOPE || (E_ALGO_SCOPE = {}));
export var E_MOVE_FOCUS_HOTKEY;
(function (E_MOVE_FOCUS_HOTKEY) {
    E_MOVE_FOCUS_HOTKEY[E_MOVE_FOCUS_HOTKEY["Default"] = 0] = "Default";
    E_MOVE_FOCUS_HOTKEY[E_MOVE_FOCUS_HOTKEY["None"] = 1] = "None";
    E_MOVE_FOCUS_HOTKEY[E_MOVE_FOCUS_HOTKEY["Tab"] = 2] = "Tab";
    E_MOVE_FOCUS_HOTKEY[E_MOVE_FOCUS_HOTKEY["Enter"] = 3] = "Enter";
})(E_MOVE_FOCUS_HOTKEY || (E_MOVE_FOCUS_HOTKEY = {}));
export var E_TRIGGER_EVENT;
(function (E_TRIGGER_EVENT) {
    E_TRIGGER_EVENT[E_TRIGGER_EVENT["ValueChange"] = 0] = "ValueChange";
    E_TRIGGER_EVENT[E_TRIGGER_EVENT["Click"] = 1] = "Click";
    E_TRIGGER_EVENT[E_TRIGGER_EVENT["DbClick"] = 2] = "DbClick";
})(E_TRIGGER_EVENT || (E_TRIGGER_EVENT = {}));
export var E_EXECUTE_MODE;
(function (E_EXECUTE_MODE) {
    E_EXECUTE_MODE[E_EXECUTE_MODE["Always"] = 0] = "Always";
    E_EXECUTE_MODE[E_EXECUTE_MODE["Once"] = 1] = "Once";
    E_EXECUTE_MODE[E_EXECUTE_MODE["Reset"] = 2] = "Reset";
})(E_EXECUTE_MODE || (E_EXECUTE_MODE = {}));
export var E_EVENT_HANDLE;
(function (E_EVENT_HANDLE) {
    E_EVENT_HANDLE["event"] = "event";
    E_EVENT_HANDLE["action"] = "action";
    E_EVENT_HANDLE["request"] = "request";
})(E_EVENT_HANDLE || (E_EVENT_HANDLE = {}));
export var E_EVENT_TYPE;
(function (E_EVENT_TYPE) {
    E_EVENT_TYPE["mouseMove"] = "mouseMove";
    E_EVENT_TYPE["mouseOut"] = "mouseOut";
    E_EVENT_TYPE["mouseOver"] = "mouseOver";
    E_EVENT_TYPE["mouseClick"] = "mouseClick";
    E_EVENT_TYPE["mouseDown"] = "mouseDown";
    E_EVENT_TYPE["mouseUp"] = "mouseUp";
    E_EVENT_TYPE["mouseWheel"] = "mouseWheel";
    E_EVENT_TYPE["touchStart"] = "touchStart";
    E_EVENT_TYPE["keyDown"] = "keyDown";
    E_EVENT_TYPE["keyUp"] = "keyUp";
    E_EVENT_TYPE["documentKeyDown"] = "documentKeyDown";
    E_EVENT_TYPE["documentKeyUp"] = "documentKeyUp";
    E_EVENT_TYPE["documentMouseMove"] = "documentMouseMove";
    E_EVENT_TYPE["documentMouseUp"] = "documentMouseUp";
})(E_EVENT_TYPE || (E_EVENT_TYPE = {}));
/**
 * @enum E_INSTANCE_EVENT_KEY
 * @description 由ThinkEditorInstance.vue $emit() 事件 给APP.vue使用
 */
export var E_INSTANCE_EVENT_KEY;
(function (E_INSTANCE_EVENT_KEY) {
    E_INSTANCE_EVENT_KEY["createdDocEditor"] = "createdDocEditor";
    E_INSTANCE_EVENT_KEY["removedDocEditor"] = "removedDocEditor";
    E_INSTANCE_EVENT_KEY["editorInstanceFocusChange"] = "editorInstanceFocusChange";
    E_INSTANCE_EVENT_KEY["createdThinkEditorView"] = "createdThinkEditorView";
    E_INSTANCE_EVENT_KEY["destroyThinkEditorView"] = "destroyThinkEditorView";
})(E_INSTANCE_EVENT_KEY || (E_INSTANCE_EVENT_KEY = {}));
export var E_EVENT_KEY;
(function (E_EVENT_KEY) {
    E_EVENT_KEY["drop"] = "drop";
    E_EVENT_KEY["requestSources"] = "requestSources";
    E_EVENT_KEY["setSources"] = "setSources";
    E_EVENT_KEY["setOptions"] = "setOptions";
    E_EVENT_KEY["delOptions"] = "delOptions";
    E_EVENT_KEY["setAlgorithms"] = "setAlgorithms";
    E_EVENT_KEY["optionResource"] = "optionResource";
    E_EVENT_KEY["elementContentChange"] = "elementContentChange";
    E_EVENT_KEY["timeStamp"] = "timeStamp";
    E_EVENT_KEY["focusChange"] = "focusChange";
    E_EVENT_KEY["buttonPressed"] = "buttonPressed";
    E_EVENT_KEY["setSearchHistory"] = "setSearchHistory";
    E_EVENT_KEY["setReplaceHistory"] = "setReplaceHistory";
    E_EVENT_KEY["setSpecialSymbolHistory"] = "setSpecialSymbolHistory";
    E_EVENT_KEY["setCursorStyle"] = "setCursorStyle";
    E_EVENT_KEY["fontFormat"] = "fontFormat";
    E_EVENT_KEY["paragraphStyle"] = "paragraphStyle";
    E_EVENT_KEY["instanceChange"] = "instanceChange";
    E_EVENT_KEY["editorFocus"] = "editorFocus";
    //docEditorFocus = "docEditorFocus", //单击到tab页等，选中了某了个文档编辑器，在应用层产生
    E_EVENT_KEY["requestFont"] = "requestFont";
    E_EVENT_KEY["fontsName"] = "fontsName";
    E_EVENT_KEY["textEditFocused"] = "textEditFocused";
    E_EVENT_KEY["docsViewChange"] = "docsViewChange";
    E_EVENT_KEY["preLoadFontData"] = "preLoadFontData";
    E_EVENT_KEY["docModified"] = "docModified";
    E_EVENT_KEY["setElementContent"] = "setElementContent";
    E_EVENT_KEY["imageResource"] = "imageResource";
    E_EVENT_KEY["closeDoc"] = "closeDoc";
    E_EVENT_KEY["parseDoc"] = "parseDoc";
    E_EVENT_KEY["loaded"] = "loaded";
    E_EVENT_KEY["saveDoc"] = "saveDoc";
    E_EVENT_KEY["visibleDocChange"] = "visibleDocChange";
    E_EVENT_KEY["algorithmCalc"] = "algorithmCalc";
    E_EVENT_KEY["selectRangeChange"] = "selectRangeChange";
    E_EVENT_KEY["user_defined1"] = "user_defined1";
    E_EVENT_KEY["browserWebglContextLost"] = "browserWebglContextLost";
    E_EVENT_KEY["printDoc"] = "printDoc";
    E_EVENT_KEY["annotateFocusChange"] = "annotateFocusChange";
    E_EVENT_KEY["setColor"] = "setColor";
    E_EVENT_KEY["openMenu"] = "openMenu";
    E_EVENT_KEY["closeMenu"] = "closeMenu";
    // beginInitTime = "beginInitTime",
    // endInitTime = "endInitTime",
    E_EVENT_KEY["beginDownloadFontTime"] = "beginDownloadFontTime";
    E_EVENT_KEY["endDownloadFontTime"] = "endDownloadFontTime";
    E_EVENT_KEY["beginParseTime"] = "beginParseTime";
    E_EVENT_KEY["endParseTime"] = "endParseTime";
    E_EVENT_KEY["beginProduceLines"] = "beginProduceLines";
    E_EVENT_KEY["endProduceLines"] = "endProduceLines";
    E_EVENT_KEY["beginProducePages"] = "beginProducePages";
    E_EVENT_KEY["endProducePages"] = "endProducePages";
    E_EVENT_KEY["beginRequestFont"] = "beginRequestFont";
    E_EVENT_KEY["endRequestFont"] = "endRequestFont";
    E_EVENT_KEY["beginParseFont"] = "beginParseFont";
    E_EVENT_KEY["endParseFont"] = "endParseFont";
    E_EVENT_KEY["openFontSetWindow"] = "openFontSetWindow";
    E_EVENT_KEY["closeFontSetWindow"] = "closeFontSetWindow";
    E_EVENT_KEY["openParagraphSetWindow"] = "openParagraphSetWindow";
    E_EVENT_KEY["closeParagraphSetWindow"] = "closeParagraphSetWindow";
    E_EVENT_KEY["openInputHandleSelector"] = "openInputHandleSelector";
    E_EVENT_KEY["closeInputHandleSelector"] = "closeInputHandleSelector";
    E_EVENT_KEY["openInfoTips"] = "openInfoTips";
    E_EVENT_KEY["closeInfoTips"] = "closeInfoTips";
    E_EVENT_KEY["openFormulaSetWindow"] = "openFormulaSetWindow";
    E_EVENT_KEY["openToothPositionSelector"] = "openToothPositionSelector";
    E_EVENT_KEY["openBarCodeSetWindow"] = "openBarCodeSetWindow";
    E_EVENT_KEY["openCheckBoxSetWindow"] = "openCheckBoxSetWindow";
    E_EVENT_KEY["openPageNumSetWindow"] = "openPageNumSetWindow";
    E_EVENT_KEY["openMessageBoxWindow"] = "openMessageBoxWindow";
    E_EVENT_KEY["closeMessageBoxWindow"] = "closeMessageBoxWindow";
    E_EVENT_KEY["openSearchReplaceWindow"] = "openSearchReplaceWindow";
    E_EVENT_KEY["openKnowledgeSetWindow"] = "openKnowledgeSetWindow";
    E_EVENT_KEY["openImageSetWindow"] = "openImageSetWindow";
    E_EVENT_KEY["openPageSetWindow"] = "openPageSetWindow";
    E_EVENT_KEY["closePageSetWindow"] = "closePageSetWindow";
    E_EVENT_KEY["openElementSetWindow"] = "openElementSetWindow";
    E_EVENT_KEY["closeElementSetWindow"] = "closeElementSetWindow";
    E_EVENT_KEY["openTableSetWindow"] = "openTableSetWindow";
})(E_EVENT_KEY || (E_EVENT_KEY = {}));
/******************枚举 end******************/
/******************数据结构******************/
export class ThinkEditorEvent extends Event {
    get data() {
        return this._data;
    }
    constructor(type, data) {
        super(type, {
            cancelable: true,
        });
        this._data = data;
    }
}
export class ThinkEditorEventData {
    constructor(handle, type, data, editor) {
        this.handle = handle;
        this.type = type;
        this.data = data;
        this.editor = editor;
    }
    get editorId() {
        var _a;
        return (_a = this.editor) === null || _a === void 0 ? void 0 : _a.editorId;
    }
}
/******************share data********************/
class SymbolItem {
    constructor(content, fontName) {
        this.content = content;
        this.fontName = fontName;
    }
}
export class ShareDataManager {
    constructor() {
        this.clipboard = {};
        this.searchHistory = new Array();
        this.replaceHistory = new Array();
        this.specialSymbolHistory = new Array();
    }
    SetSearchHistory(searchHistory) {
        this.searchHistory = searchHistory;
    }
    GetSearchHistory() {
        return this.searchHistory;
    }
    SetReplaceHistory(replaceHistory) {
        this.replaceHistory = replaceHistory;
    }
    GetReplaceHistory() {
        return this.replaceHistory;
    }
    SetSpecialSymbolHistory(history) {
        this.specialSymbolHistory = history;
    }
    GetSpecialSymbolHistory() {
        return this.specialSymbolHistory;
    }
}
/******************Source******************/
export class Source {
    constructor(sourceClass, sourceId, content) {
        this.sourceClass = "";
        this.sourceId = "";
        this.sourceClass = sourceClass;
        this.sourceId = sourceId;
        this.content = content;
    }
}
export class SourceItem {
    constructor(sourceId, content) {
        this.sourceId = "";
        this.sourceId = sourceId;
        this.content = content;
    }
}
export class SourceClass {
    constructor(sourceClass) {
        this.sourceClass = "";
        this.sourceItems = new Array();
        this.sourceClass = sourceClass;
    }
    GetSourceItem(sourceId) {
        return this.sourceItems.find((x) => x.sourceId == sourceId);
    }
}
/**********************************************************
源管理
**********************************************************/
export class SourcesManager {
    constructor() {
        this.sources = new Array();
    }
    AddSource(sourceClass, sourceId, content) {
        let existSourceClass = this.GetSrouceClass(sourceClass);
        if (existSourceClass === undefined) {
            existSourceClass = new SourceClass(sourceClass);
            this.sources.push(existSourceClass);
        }
        let existSourceItem = existSourceClass.GetSourceItem(sourceId);
        if (existSourceItem === undefined) {
            existSourceClass.sourceItems.push(new SourceItem(sourceId, content));
        }
        else if (existSourceItem.content === undefined) {
            existSourceItem.content = content; //只更新内容
        }
    }
    AddSources(sources) {
        for (let sourceIdx in sources) {
            let source = sources[sourceIdx];
            let existSourceClass = this.GetSrouceClass(source.sourceClass);
            if (existSourceClass === undefined) {
                existSourceClass = new SourceClass(source.sourceClass);
                this.sources.push(existSourceClass);
            }
            let existSourceItem = existSourceClass.GetSourceItem(source.sourceId);
            if (existSourceItem === undefined) {
                existSourceClass.sourceItems.push(new SourceItem(source.sourceId, source.content));
            }
            else if (existSourceItem.content === undefined && source.content !== undefined) {
                existSourceItem.content = source.content;
            }
        }
    }
    GetSrouceItem(sourceClass, sourceId) {
        let existSourceClass = this.GetSrouceClass(sourceClass);
        if (undefined === existSourceClass) {
            return undefined;
        }
        return existSourceClass.GetSourceItem(sourceId);
    }
    GetSource(sourceClass, sourceId) {
        let existSourceClass = this.GetSrouceClass(sourceClass);
        if (undefined === existSourceClass) {
            return undefined;
        }
        let existSourceItem = existSourceClass.GetSourceItem(sourceId);
        if (existSourceItem === undefined) {
            return undefined;
        }
        return new Source(existSourceClass.sourceClass, existSourceItem.sourceId, existSourceItem.content);
    }
    GetAllSources() {
        let sourcesArr = [];
        for (let source of this.sources) {
            for (let sourceId of source.sourceItems) {
                sourcesArr.push(new Source(source.sourceClass, sourceId.sourceId, sourceId.content));
            }
        }
        return sourcesArr;
    }
    GetSrouceClass(sourceClass) {
        return this.sources.find((x) => x.sourceClass == sourceClass);
    }
}
/**********************************************************
选项知识库
**********************************************************/
export class OptionItem {
    constructor(id, group, weight, content) {
        this.id = "";
        this.group = ""; //@20240323 old "1"
        this.weight = 0.0; //@20240323 old 1.0
        this.content = "";
        this.id = id;
        this.group = group;
        this.weight = weight;
        this.content = content;
    }
}
export class Option {
    constructor(optionClass, optionId, multipleChoice = false, groupExclusion = false) {
        this.optionClass = ""; //冗余，兼容要设置的参数
        this.optionId = "";
        //public notSave: boolean = false;
        this.multipleChoice = false;
        this.groupExclusion = false;
        this.items = new Array();
        this.optionClass = optionClass;
        this.optionId = optionId;
        this.multipleChoice = multipleChoice;
        this.groupExclusion = groupExclusion;
    }
    RemoveInvalidData() {
        for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];
            if (item.id == "") {
                this.items.splice(i, 1); //移除item
                i--;
            }
        }
    }
}
export class OptionClass {
    constructor(optionClass) {
        this.optionClass = "";
        this.options = new Array();
        this.optionClass = optionClass;
    }
    GetOption(optionIdStr) {
        for (let optionId of this.options) {
            if (optionId.optionId == optionIdStr) {
                return optionId;
            }
        }
        return undefined;
    }
    AddOption(optionId) {
        this.options.push(optionId);
    }
    DeleteOption(optionIdStr) {
        for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].optionId == optionIdStr) {
                this.options.splice(i, 1);
                return true;
            }
        }
        return false;
    }
    RemoveInvalidData() {
        for (let i = 0; i < this.options.length; i++) {
            let optionId = this.options[i];
            optionId.RemoveInvalidData(); //移除无效items
            if (optionId.items.length == 0) {
                this.options.splice(i, 1); //移除optionId
                i--;
            }
        }
    }
}
export class OptionsManager {
    constructor() {
        this.options = new Array();
    }
    AddOptions(options, forceUpdate = true) {
        for (let option of options) {
            this.AddOption(option.optionClass, option.optionId, option.items, forceUpdate);
        }
    }
    AddOption(optionClass, optionId, items, forceUpdate = true) {
        let existOptionClass = this.GetOptionClass(optionClass);
        if (existOptionClass == undefined) {
            existOptionClass = new OptionClass(optionClass);
            this.options.push(existOptionClass);
        }
        let existOptionObj = existOptionClass.GetOption(optionId);
        if (existOptionObj == undefined) {
            existOptionObj = new Option(optionClass, optionId);
            existOptionObj.items = items;
            existOptionClass.AddOption(existOptionObj);
        }
        else {
            //else if (items.length > 0 && existOptionIdObj.items.length == 0)
            if (forceUpdate === undefined || forceUpdate == true) {
                //存在，(除了解析文档forceModify=false)InnerUI修改始终旧的覆盖新的
                existOptionObj.items = items;
            }
        }
    }
    GetOptionClass(optionClass) {
        for (let option of this.options) {
            if (option.optionClass == optionClass) {
                return option;
            }
        }
        return undefined;
    }
    GetOption(optionClass, optionId) {
        let optionClassObj = this.GetOptionClass(optionClass);
        if (optionClassObj === undefined) {
            return undefined;
        }
        let optionObj = optionClassObj.GetOption(optionId);
        if (optionObj === undefined) {
            return undefined;
        }
        return optionObj;
    }
    DeleteOption(optionClass, optionId) {
        let optionClassObj = this.GetOptionClass(optionClass);
        if (optionClassObj === undefined) {
            return false;
        }
        return optionClassObj.DeleteOption(optionId);
    }
    /**
     * @function GetOptionsOutline
     * @description 返回optionClass,optionId的名，不包括Items。用于InnerUI显示配置框
     * @param {}
     * @return {}
     * @example
     */
    //public GetOptionsOutline() {
    //return JSON.stringify(this.options, (key, val) => {
    //return key == "items" ? undefined : val;
    //});
    //}
    GetAllOptions() {
        let optionsArr = [];
        for (let optionClass of this.options) {
            for (let option of optionClass.options) {
                optionsArr.push({ optionClass: option.optionClass, optionId: option.optionId, multipleChoice: option.multipleChoice, groupExclusion: option.groupExclusion });
            }
        }
        return optionsArr;
    }
    /**
     * @function RemoveInvalidData
     * @description OptionClass.optionIds 或 OptionId.items 为空时，则会移除OptionClass或OptionId
     * @param {}
     * @return {}
     * @example
     */
    RemoveInvalidData() {
        for (let i = 0; i < this.options.length; i++) {
            let option = this.options[i];
            option.RemoveInvalidData();
            if (option.options.length == 0) {
                this.options.splice(i, 1);
                i--;
            }
        }
    }
}
/**********************************************************
算法知识库
**********************************************************/
export class Algorithm {
    constructor(algoGroup, algoMode, algoScope) {
        this.algoGroup = "";
        this.algoMode = E_ALGO_MODE.Common;
        this.algoScope = E_ALGO_SCOPE.Doc;
        this.algoGroup = algoGroup;
        this.algoMode = algoMode;
        this.algoScope = algoScope;
    }
}
export class AlgorithmsManager {
    constructor() {
        this.algorithms = new Array();
    }
    AddAlgorithms(algorithms) {
        for (let algorithm of algorithms) {
            this.AddAlgorithm(algorithm.algoGroup, algorithm.algoMode, algorithm.algoScope);
        }
    }
    AddAlgorithm(algoGroup, algoMode, algoScope) {
        let existAlgorithm = this.GetAlgorithm(algoGroup);
        if (existAlgorithm === undefined) {
            this.algorithms.push(new Algorithm(algoGroup, algoMode, algoScope));
        }
        else {
            existAlgorithm.algoMode = algoMode;
            existAlgorithm.algoScope = algoScope;
        }
    }
    DelAlgorithm(algoGroup) {
        for (let i = 0; i < this.algorithms.length; i++) {
            let algorithm = this.algorithms[i];
            if (algorithm.algoGroup == algoGroup) {
                this.algorithms.splice(i, 1);
                return true;
            }
        }
        return false;
    }
    GetAlgorithm(algoGroup) {
        for (let i = 0; i < this.algorithms.length; i++) {
            let algorithm = this.algorithms[i];
            if (algorithm.algoGroup == algoGroup) {
                return algorithm;
            }
        }
        return undefined;
    }
    RemoveInvalidData() {
        for (let i = 0; i < this.algorithms.length; i++) {
            let algorithm = this.algorithms[i];
            if (algorithm.algoGroup == "") {
                this.algorithms.splice(i, 1);
                i--;
            }
        }
    }
}
/**********************************************************
表达式
**********************************************************/
export class Expression {
    constructor(event, mode, action) {
        this.event = E_TRIGGER_EVENT.ValueChange;
        this.mode = E_EXECUTE_MODE.Always;
        this.action = "";
        this.event = event;
        this.mode = mode;
        this.action = action;
    }
}
export class ExpressionsManager {
    constructor() {
        this.expressions = new Array();
    }
    AddExpressions(expressions) {
        for (let expression of expressions) {
            this.AddExpression(expression.event, expression.mode, expression.action);
        }
    }
    AddExpression(event, mode, action) {
        let existExpression = this.GetExpression(event, mode, action);
        if (existExpression === undefined) {
            this.expressions.push(new Expression(event, mode, action));
        }
    }
    GetExpression(event, mode, action) {
        for (let i = 0; i < this.expressions.length; i++) {
            let expression = this.expressions[i];
            if (expression.event == event && expression.mode == mode && expression.action == action) {
                return expression;
            }
        }
        return undefined;
    }
    RemoveInvalidData() {
        for (let i = 0; i < this.expressions.length; i++) {
            let expression = this.expressions[i];
            if (expression.action == "") {
                this.expressions.splice(i, 1);
                i--;
            }
        }
    }
}
export class ThinkEditorLogger {
    constructor() {
        this.logLevel = undefined;
    }
    InitLogLevel(logLevel) {
        if (logLevel != undefined && this.logLevel == undefined) {
            this.logLevel = logLevel;
        }
    }
    NeedLogError() {
        return true;
    }
    NeedLogWarn() {
        return this.logLevel == undefined || this.logLevel >= E_LOG_LEVEL.Warn;
    }
    NeedLogInfo() {
        return this.logLevel == undefined || this.logLevel >= E_LOG_LEVEL.Info;
    }
    NeedLogDebug() {
        return this.logLevel == undefined || this.logLevel >= E_LOG_LEVEL.Debug;
    }
    NeedLogEvent() {
        return this.NeedLogDebug();
    }
    LogError(...data) {
        if (this.NeedLogError()) {
            console.error.apply(null, data);
        }
    }
    LogWarn(...data) {
        if (this.NeedLogWarn()) {
            console.warn.apply(null, data);
        }
    }
    LogInfo(...data) {
        if (this.NeedLogInfo()) {
            console.log.apply(null, data);
        }
    }
    LogDebug(...data) {
        if (this.NeedLogDebug()) {
            console.log.apply(null, data);
        }
    }
    LogEvent(...data) {
        if (this.NeedLogEvent()) {
            console.log.apply(null, data);
        }
    }
}
export class FontsManager {
    constructor() {
        this.fontsDataMap = new Map();
        this.fontsNameList = new Array();
        this.localFontDatas = new Array();
        this.debugFont = true;
        this.forbidLocalFonts = false; //禁用本地字体功能
    }
    /*
    public IsBrowserSupportLocalFonts() {
      if ("queryLocalFonts" in window) {
        return true;
      } else {
        return false;
      }
    }
    public async CheckLocalFontsPermission() {
      const { state } = await navigator.permissions.query({
        name: "local-fonts",
      } as any);
      if (state === "granted") {
        return true;
      }
  
      return false;
    }*/
    //AddFont 用于加载 思源字体
    AddFont(fullName, fontData) {
        let fontDataTemp = this.fontsDataMap.get(fullName);
        if (fontDataTemp === undefined) {
            this.fontsDataMap.set(fullName, fontData);
        }
    }
    ///GetFontData ArrayBuffer
    async GetFontData(fullName) {
        let fontData = this.fontsDataMap.get(fullName);
        if (fontData != undefined) {
            return fontData;
        }
        await this.LoadLocalFonts();
        for (let i = 0; i < this.localFontDatas.length; i++) {
            let localFontDataObj = this.localFontDatas[i];
            if (localFontDataObj.fullName == fullName) {
                //本地字体，存在需要的字体
                let localFontDataBolb = await localFontDataObj.blob();
                let localFontData = await localFontDataBolb.arrayBuffer();
                this.AddFont(fullName, localFontData);
                return localFontData;
            }
        }
        return undefined;
    }
    IsForbidLocalFonts() {
        if (this.forbidLocalFonts) {
            return true;
        }
        return !IsHavingAdvancedPermissions();
    }
    async LoadLocalFonts() {
        if (this.IsForbidLocalFonts()) {
            if (!this.hasLogForbidLocalFonts) {
                this.hasLogForbidLocalFonts = true;
                console.warn("本地字体LocalFonts功能已被厂商强制关闭！");
            }
            return false;
        }
        //step1: 判断浏览器是否支持localFonts功能 IsBrowserSupportLocalFonts
        //if (this.hasCatchQueryLocalFontsApiError == true) {
        //  return false;
        //}
        if (!IsBrowserSupportLocalFonts()) {
            //不支持
            if (!this.hasBrowserSupportLocalFontsNoticeOnce) {
                this.hasBrowserSupportLocalFontsNoticeOnce = true;
                let msg = "当前浏览器不支持矢量字体功能，为保证更好的体验，请务必联系技术人员更换浏览器或升级浏览器版本！";
                console.error("Font：", msg);
                if (this.debugFont) {
                    alert(msg);
                }
            }
            return false;
        }
        if (this.localFontDatas.length != 0) {
            //已经获得了本地字体
            return true;
        }
        //hasLocalFontsPermissionDeniedNoticeOnce
        // const { state } = await navigator.permissions.query({
        //  name: "local-fonts",
        // } as any);
        let permissionState = await GetLocalFontsPermissionState();
        // state 的值是 'granted'、'denied'、'prompt':
        if (permissionState === "denied") {
            if (!this.hasLocalFontsPermissionDeniedNoticeOnce) {
                this.hasLocalFontsPermissionDeniedNoticeOnce = true;
                let msg = "当前浏览器支持矢量字体功能, 首次使用需人为触发授权, 但相关功能已被禁止！请开启浏览器相关权限！";
                console.error("Font：", msg);
                if (this.debugFont) {
                    alert(msg);
                }
            }
            return false; //禁止
        }
        else if (permissionState === "granted") {
            try {
                this.localFontDatas = await window.queryLocalFonts();
            }
            catch (e) {
                this.hasCatchQueryLocalFontsApiError = true;
                let msg = `当前环境${getOsInfo()} ${getBrowerInfo()}支持本地字体功能&已获取到相关授权，但是出现异常，请联系管理员处理！${e}`;
                console.error("window.queryLocalFonts: ", msg);
                if (this.debugFont) {
                    alert(msg);
                }
            }
            return true;
        }
        else {
            //step3: 获取全部本地字体（待用）
            this.localFontDatas = (await this.LetUserAuthLocalFontAccessPermission()) || [];
            return true;
        }
    }
    async LetUserAuthLocalFontAccessPermission() {
        //必须弹出UI框，让用户点击后才可以弹出浏览器的 授权访问本地字体 授权框
        return new Promise((resolve, reject) => {
            let template = `
      <!-- 底部透明灰色层 -->
      <div class='mask' style="position:fixed;top:0;left:0;z-index:998;width:100%;height:100%;display:block;background-color:#000;opacity:0.5;overflow:hidden;"></div>
      <!-- 提示层 -->
      <div class="board" style="position:fixed;border-radius: 12px;background-color:#fff;margin:auto;width: 400px;height:200px;z-index:999;display:block;top: 0;left: 0;right: 0;bottom: 0;">
        <div class="logo" style="padding:10px;height:20%;margin-left:0px;text-align:center;color: #000;">
          <span style="display:block;color:#000;font-size:16px;font-weight:700;text-align:center;padding-top:5.4%;margin: 0 auto;">提示</span>
        </div>
        <div class="qrcode" style="margin-top:10px;width: 76%;margin-left:12%;text-align:center;color: #000;">首次使用，请在随后弹出的提示框进行访问本机字体授权-点击"允许"</div>
        <div class="heart" style="margin-top:40px">
          <span class="isok" style="text-align:center;background: #ebeef5;display:block;width:30.4%;margin:0 auto;font-size:14px;color: #000000;font-weight:700;height:36px;border-radius:12px;line-height:36px;border: 1px solid #ebeef5;" id="alertSure">去授权</span>
        </div>
      </div>
      `;
            let domParser = new DOMParser();
            let doc = domParser.parseFromString(template, "text/html");
            let mask = doc.querySelector(".mask");
            let board = doc.querySelector(".board");
            let isok = doc.querySelector(".isok");
            isok.addEventListener("click", () => {
                document.body.removeChild(mask);
                document.body.removeChild(board);
                window
                    .queryLocalFonts()
                    .then((res) => {
                    resolve(res);
                })
                    .catch((err) => {
                    reject(err);
                });
            });
            document.body.appendChild(mask);
            document.body.appendChild(board);
        });
    }
    async GetfontsNameList() {
        await this.LoadLocalFonts();
        var fontNameArray = new Array();
        for (const fontData of this.localFontDatas) {
            let fontName = fontData.fullName;
            fontNameArray.push(fontName);
        }
        if (fontNameArray.length == 0) {
            fontNameArray.push("宋体");
            fontNameArray.push("黑体");
        }
        this.fontsNameList = fontNameArray;
        return JSON.parse(JSON.stringify(this.fontsNameList));
    }
}
const editorLogger = new ThinkEditorLogger();
const fontsManager = new FontsManager();
/*
  默认先使用optionsManager、algorithmsManager、sourcesManager的资源 再使用App中的资源
*/
const optionsManager = new OptionsManager();
const algorithmsManager = new AlgorithmsManager();
const shareDataManager = new ShareDataManager();
/*********************************************************/
/******************数据结构 end******************/
export { fontsManager, optionsManager, algorithmsManager, shareDataManager, editorLogger };
