<template>
  <div id="app">
    <!-- <canvas id="test" width="300" height="300" style="width: 300px; height: 300px"></canvas> -->
    <el-container>
      <TreeView :modeType="modeType" @openFile="OpenSelectNodeDoc" @doDemoCase="DoDemoCase"></TreeView>
      <el-container>
        <el-header style="height: auto">
          <div class="h-main">
            <EditorHeader class="h-left" @addEditorInstance="OnShowMoreEditorInstance" @removeEditorInstance="OnHideLastEditorInstance" @closeDocEditor="OnCloseDocEditor" @closeAllDocEditor="OnCloseAllDocEditor" @docOpt="OnDocOpt" />
            <div class="h-middle">
              <el-row v-if="testMode == 'SetTimeDataTest'">
                <!--
                <el-button size="mini" type="success" @click="OnOpenTimeAxisSetWindow">编辑时间轴</el-button>
                <el-button size="mini" type="primary" @click="OnCloseTimeAxisSetWindow">退出编辑</el-button>
              -->
                <el-button size="mini" type="primary" @click="OnSetTimeDataAppendTest">追加三测单数据</el-button>
                <el-button size="mini" type="success" @click="OnSetTimeDataUpdateTest">重设三测单数据</el-button>
              </el-row>
              <el-row v-if="testMode == 'SetTableDataTest'">
                <el-button size="mini" type="primary" @click="OnSetTableDataAppendTest">追加表格数据</el-button>
                <el-button size="mini" type="success" @click="OnSetTableDataUpdateTest">重设表格数据</el-button>
              </el-row>
              <el-row v-if="testMode == 'SetCellDataTestStyle1'">
                <el-button size="mini" type="primary" @click="OnSetCellDataTestStyle1(1, 3)">1影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestStyle1(2, 3)">2影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestStyle1(3, 3)">3影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestStyle1(4, 3)">4影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestStyle1(5, 3)">5影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestStyle1(6, 3)">6影像</el-button>
              </el-row>
              <el-row v-if="testMode == 'SetCellDataTestCommon'">
                <el-button size="mini" type="primary" @click="OnSetCellDataTestCommon(1, 3)">1影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestCommon(2, 3)">2影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestCommon(3, 3)">3影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestCommon(4, 3)">4影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestCommon(5, 3)">5影像</el-button>
                <el-button size="mini" type="primary" @click="OnSetCellDataTestCommon(6, 3)">6影像</el-button>
              </el-row>
              <el-row v-if="testMode == 'DynamicOptionsTest'">
                <el-button size="mini" type="primary" @click="OnDynamicOptionsTest(0)">昨日患者</el-button>
                <el-button size="mini" type="success" @click="OnDynamicOptionsTest(1)">今日患者</el-button>
              </el-row>
              <el-row v-if="testMode == 'QualityControlTest'">
                <el-button size="mini" type="primary" @click="OnGetQualityControlReportTest()">获得质控报告</el-button>
                <el-button size="mini" type="success" @click="OnGotoNextQualityControlTest()">下一个</el-button>
                <el-button size="mini" type="success" @click="OnGotoPreviousQualityControlTest()">上一个</el-button>
                <el-button size="mini" type="primary" @click="OnDocQualityControlTest()">文档校验</el-button>
              </el-row>
              <el-row v-if="testMode == 'CommentTest'">
                <el-button size="mini" type="primary" @click="OnGetCommentReportTest()">获得批注报告</el-button>
                <el-button size="mini" type="success" @click="OnGotoNextCommentTest()">下一个</el-button>
                <el-button size="mini" type="success" @click="OnGotoPreviousCommentTest()">上一个</el-button>
                <el-button size="mini" type="primary" @click="OnInsertCommentTest()">插入批注</el-button>
                <el-button size="mini" type="warning" @click="OnDeleteCommentTest()">删除批注</el-button>
                <el-button size="mini" type="primary" @click="OnInsertReplyCommentTest()">答复批注</el-button>
                <el-button size="mini" type="warning" @click="OnDeleteReplyCommentTest()">删除答复</el-button>
              </el-row>
              <el-row v-if="testMode == 'ReviseTest'">
                <el-button size="mini" type="primary" @click="OnGetReviseReportTest()">获得痕迹报告</el-button>
                <el-button size="mini" type="success" @click="OnGotoNextReviseTest()">下一个</el-button>
                <el-button size="mini" type="success" @click="OnGotoPreviousReviseTest()">上一个</el-button>
                <el-button size="mini" type="primary" @click="OnLowerPermissionEditTest()">低权限编辑者0</el-button>
                <el-button size="mini" type="primary" @click="OnMiddlePermissionEditTest()">中权限编辑者1</el-button>
                <el-button size="mini" type="primary" @click="OnHigherPermissionEditTest()">高权限编辑者2</el-button>
              </el-row>
              <el-row v-if="testMode == 'ButtonTest'">
                <el-button size="mini" type="primary" @click="OnInsertButtonAddRowTest()">插入新增行button</el-button>
                <el-button size="mini" type="success" @click="OnInsertButtonDelRowTest()">插入删除行button</el-button>
              </el-row>
              <el-row v-if="testMode == 'OutlineTest'">
                <el-button size="mini" type="primary" @click="OnGetOutlineReportTest()">获得大纲报告</el-button>
                <el-button size="mini" type="success" @click="OnGotoOutlineNextTest()">下一个</el-button>
                <el-button size="mini" type="success" @click="OnGotoOutlinePreviousTest()">上一个</el-button>
              </el-row>
              <el-row v-if="testMode == 'SearchReplaceTest'">
                <el-button size="mini" type="primary" @click="OnDisplaySearchReplaceWindowTest()">Ctrl+F</el-button>
              </el-row>
              <el-row v-if="testMode == 'WaterMarkTest'">
                <el-button size="mini" type="primary" @click="OnAddTextWaterMarkTest()">添加文字水印</el-button>
                <el-button size="mini" type="primary" @click="OnAddImageWaterMarkTest()">添加图片水印</el-button>
                <el-button size="mini" type="primary" @click="OnDelTextWaterMarkTest()">移除文字水印</el-button>
                <el-button size="mini" type="primary" @click="OnDelImageWaterMarkTest()">移除图片水印</el-button>
                <el-button size="mini" type="success" @click="OnGetWaterMarkReportTest()">获得水印报告</el-button>
              </el-row>
              <el-row v-if="testMode == 'DisplayScaleTest'">
                <el-button size="mini" type="primary" @click="OnSetDisplayScale1Test()">比例显示100%</el-button>
                <el-button size="mini" type="primary" @click="OnSetDisplayScale15Test()">比例显示150%</el-button>
                <el-button size="mini" type="success" @click="OnSetDisplayScaleFillViewWidthTest()">填满可视区域</el-button>
              </el-row>
              <el-row v-if="testMode == 'GetSelectRangeContentTest'">
                <el-button size="mini" type="primary" @click="OnGetSelectRangeContentXmlTest()">获取XML</el-button>
                <el-button size="mini" type="primary" @click="OnGetSelectRangeContentTextTest()">获取纯文本</el-button>
                <el-button size="mini" type="success" @click="OnGetSelectRangeContentHtmlTest()">获取Html</el-button>
              </el-row>
              <el-row v-if="testMode == 'RowOwnerTest'">
                <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_1()">李护士编辑</el-button>
                <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_2()">张护士编辑</el-button>
                <el-button size="mini" type="success" @click="OnSetRowOwnerAndSignTest()">编辑者获得当前行-所属权限-并签名</el-button>
                <el-button size="mini" type="success" @click="OnClearRowOwnerTest()">解除当前行-所属权限</el-button>
              </el-row>
              <el-row v-if="testMode == 'CheckDocIdTest'" type="flex" align="middle">
                <el-col :span="7"><span style="color: blue">设置文档DocId不一致时粘贴行为</span></el-col>
                <el-col :span="8">
                  <el-select v-model="pasteCheckDocIdMode" placeholder="请选择" @change="OnPasteCheckDocIdModeChange">
                    <el-option v-for="item in pasteCheckBehaviorModeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row v-if="testMode == 'CheckUserIdTest'" type="flex" align="middle">
                <el-col :span="7"><span style="color: blue">设置文档userId不一致时粘贴行为</span></el-col>
                <el-col :span="8">
                  <el-select v-model="pasteCheckDocIdMode" placeholder="请选择" @change="OnPasteCheckUserIdModeChange">
                    <el-option v-for="item in pasteCheckBehaviorModeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row v-if="testMode == 'ForbidCopyToOuterTest'" type="flex" align="middle">
                <el-button size="mini" type="primary" @click="OnAllowCopyToOuterTest()">允许复制到外部</el-button>
                <el-button size="mini" type="primary" @click="OnForbidCopyToOuterTest()">禁止复制到外部</el-button>
              </el-row>
              <el-row v-if="testMode == 'ForbidPasteFromOuterTest'" type="flex" align="middle">
                <el-button size="mini" type="primary" @click="OnAllowPasteFromOuterTest()">允许粘贴外部内容</el-button>
                <el-button size="mini" type="primary" @click="OnForbidPasteFromOuterTest()">禁止粘贴外部内容</el-button>
              </el-row>
              <el-row v-if="testMode == 'OwnerTest'" type="flex" align="middle">
                <el-col :span="6">
                  <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_1()">李护士编辑</el-button>
                  <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_2()">张护士编辑</el-button>
                </el-col>
                <el-col :span="3">
                  <el-select v-model="ownerClassValue" placeholder="请选择">
                    <el-option v-for="item in optionsClaimClass" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="15">
                  <el-button size="mini" type="primary" @click="OnSetObjectOwnerTest()">配置对象[归属]当前编辑者</el-button>
                  <el-button size="mini" type="success" @click="OnClearObjectOwnerTest()">清除对象[归属]配置</el-button>
                </el-col>
              </el-row>
              <el-row v-if="testMode == 'ClaimTest'" type="flex" align="middle">
                <el-col :span="10">
                  <el-tooltip class="item" effect="dark" content="claim1" placement="top-start">
                    <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_主治医生1()">主治医生1</el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="claim1" placement="top-start">
                    <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_主治医生2()">主治医生2</el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="claim2" placement="top-start">
                    <el-button size="mini" type="success" @click="OnSetEditorInfoTest_上级医生()">上级医生</el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="claim1;claim2;" placement="top-start">
                    <el-button size="mini" type="primary" @click="OnSetEditorInfoTest_主任医生()">主任医生</el-button>
                  </el-tooltip>
                </el-col>
                <el-col :span="3">
                  <el-select v-model="claimClassValue" placeholder="请选择">
                    <el-option v-for="item in optionsClaimClass" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="3">
                  <el-autocomplete class="inline-input" v-model="inputClaimValue" :fetch-suggestions="queryClaimSuggestions" placeholder="请输入内容"></el-autocomplete>
                </el-col>
                <el-col :span="8">
                  <el-button size="mini" type="primary" @click="OnSetObjectClaimTest()">配置声明</el-button>
                  <el-button size="mini" type="success" @click="OnClearObjectClaimTest()">清除声明</el-button>
                </el-col>
              </el-row>
              <el-row v-show="testMode == 'DraggerTest'">
                <el-button id="draggerAndInsertElement" size="mini" type="primary" draggable="true">拖拽插入元素</el-button>
                <el-button id="draggerAndInputFragment" size="mini" type="primary" draggable="true">拖拽插入片段</el-button>
              </el-row>
              <el-row v-show="testMode == 'GenerateHealthReportTest'">
                <el-button size="mini" type="success" @click="OnGenerateHealthReportTest()">一键动态生成体检报告</el-button>
              </el-row>
            </div>
            <!--<el-radio-group class="h-right" v-model="enableRevise" @change="OnEnableReviseChange">
              <el-radio :label="0">不带痕</el-radio>
              <el-radio :label="1">带痕</el-radio>
            </el-radio-group>-->
            <!--<el-form-item label="姓名">
                <el-input v-model="formInline.user" placeholder="姓名"></el-input>
              </el-form-item>
              <el-form-item label="就诊号">
                <el-input v-model="formInline.user" placeholder="就诊号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>-->
          </div>
          <el-tabs type="border-card" class="opear-btn">
            <el-tab-pane label="文件">
              <EditorFolder @toolbarClick="toolbarClick" />
            </el-tab-pane>
            <el-tab-pane label="编辑">
              <EditTags></EditTags>
            </el-tab-pane>
            <el-tab-pane label="插入">
              <EditorInstall @installBtnClick="getSelectOpera"></EditorInstall>
            </el-tab-pane>
            <el-tab-pane label="功能">
              <FuncTab @OnFuncClick="OnFuncClick"></FuncTab>
            </el-tab-pane>
          </el-tabs>
        </el-header>

        <!-- <MainCom ref="mainDom"></MainCom> -->

        <div class="editor-container-box">
          <div class="com-row" :style="{ height: calcFirstRowHight }">
            <EditorsTabsView ref="editorInstance1" :instanceId="editorInstanceId1" :focus="editorInstanceFocus1" @dispatchEditorInstanceEvent="OnEditorInstanceEvent" v-show="editorInstanceShow1" />
            <EditorsTabsView ref="editorInstance2" :instanceId="editorInstanceId2" :focus="editorInstanceFocus2" @dispatchEditorInstanceEvent="OnEditorInstanceEvent" v-show="editorInstanceShow2" />
          </div>
          <div class="com-row" :style="{ height: calcSecondRowHight }">
            <EditorsTabsView ref="editorInstance3" :instanceId="editorInstanceId3" :focus="editorInstanceFocus3" @dispatchEditorInstanceEvent="OnEditorInstanceEvent" v-show="editorInstanceShow3" />
            <EditorsTabsView ref="editorInstance4" :instanceId="editorInstanceId4" :focus="editorInstanceFocus4" @dispatchEditorInstanceEvent="OnEditorInstanceEvent" v-show="editorInstanceShow4" />
          </div>
          <el-dialog :title="popDialogTitle" :visible.sync="popDialogVisible" width="50%">
            <pre v-html="popDialogText"></pre>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="popDialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-container>
    </el-container>
    <div style="clear: both; text-align: center; bottom: 0; margin: 0 auto; width: 100%; color: #5c6b77">
      <a target="_blank" style="color: #5c6b77" href="http://beian.miit.gov.cn">蜀ICP备2021021503号</a>
      &nbsp;
    </div>
  </div>
</template>

<script>
import EditorsTabsView from './view/editor/EditorsTabsView.vue';
import TreeView from './view/editor/treeView';
import EditTags from '@/components/EditTags/index.vue';
import EditorHeader from '@/components/EditorHeader.vue';
import EditorInstall from '@/components/EditorInstall.vue';
import FuncTab from '@/components/FuncTab.vue';

import EditorFolder from '@/components/EditorFolder.vue';
import Sortable from 'sortablejs';

import { ThinkEditor } from './components/editor/ThinkEditor';
import { Source, editorLogger, E_LOG_LEVEL, E_SYSTEM_AUTH_MODE, Option, OptionItem, Algorithm, E_ALGO_MODE, E_ALGO_SCOPE, E_DOC_TYPE, E_EVENT_KEY, E_VIEW_MODE, E_DISPLAY_MODE, E_DOCS_ORGANIZE_MODE, E_PAGES_LAYOUT_MODE, ThinkEditorEvent, ThinkEditorEventData } from '@/components/editor/ThinkEditor.Defined';
import { CalcDocName, PopFileSelector, GetRandStr } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
import { ThinkEditorDemo } from '@/demo/ThinkEditorDemo';
import { AppStable } from './App.Stable';
import { optionsManager, OptionsManager, algorithmsManager } from '@/components/editor/ThinkEditor.Defined';
import { AppUserDefined } from './App.UserDefined';
import { DemoCaseMultDocPrint } from './democases/App.MultDocPrint';
import { DemoCaseMultDocBackgroundPrint } from './democases/App.MultDocBackgroundPrint';
import { DemoCaseRotatePrint } from './democases/App.RotatePrint';
import { DemoCasePagesRangePrint } from './democases/App.PagesRangePrint';
import { DemoCaseOddEvenPrint } from './democases/App.OddEvenPrint';
import { DemoCaseHideElementHintPrint } from './democases/App.HideElementHintPrint';
import { DemoCaseQualityControl } from './democases/App.QualityControl';
import { DemoCaseComment } from './democases/App.Comment';
import { DemoCaseRevise } from './democases/App.Revise';
import { DemoCaseButton } from './democases/App.Button';
import { DemoCaseOutline } from './democases/App.Outline';
import { DemoCaseSearchReplace } from './democases/App.SearchReplace';
import { DemoCaseWaterMark } from './democases/App.WaterMark';
import { DemoCaseDisplayScale } from './democases/App.DisplayScale';
import { DemoCaseGetSelectRangeContent } from './democases/App.GetSelectRangeContent';
import { DemoCaseRowOwnerAndSign } from './democases/App.RowOwnerAndSign';
import { DemoCaseOwner } from './democases/App.Owner';
import { DemoCaseClaim } from './democases/App.Claim';
import { DemoCaseCheckDocId } from './democases/App.PasteCheckDocId';
import { DemoCaseCheckUserId } from './democases/App.PasteCheckUserId';
import { DemoCaseGetPrintData } from './democases/App.GetPrintData';
import { DemoCaseObjectHideNoDrawPrint } from './democases/App.ObjectHideNoDrawPrint';
import { DemoCaseDragger } from './democases/App.Dragger';
import { DemoCaseForbidCopyToOuter } from './democases/App.ForbidCopyToOuter';
import { DemoCaseForbidPasteFromOuter } from './democases/App.ForbidPasteFromOuter';
import { DemoCaseGenerateHealthReport } from './democases/App.GenerateHealthReport';
import { barCodeImageBase64Str } from '@/testmaterial/BarCodeImageBase64Str.js';
export default {
  name: 'App',
  mixins: [DemoCaseGenerateHealthReport, DemoCaseForbidPasteFromOuter, DemoCaseForbidCopyToOuter, DemoCaseDragger, DemoCaseObjectHideNoDrawPrint, DemoCaseGetPrintData, DemoCaseCheckUserId, DemoCaseCheckDocId, DemoCaseClaim, DemoCaseOwner, DemoCaseRowOwnerAndSign, DemoCaseGetSelectRangeContent, DemoCaseDisplayScale, DemoCaseWaterMark, DemoCaseSearchReplace, DemoCaseOutline, DemoCaseButton, DemoCaseRevise, DemoCaseComment, AppUserDefined, AppStable, DemoCaseMultDocPrint, DemoCaseMultDocBackgroundPrint, DemoCaseRotatePrint, DemoCasePagesRangePrint, DemoCaseOddEvenPrint, DemoCaseHideElementHintPrint, DemoCaseQualityControl],
  components: {
    EditorsTabsView,
    TreeView,
    EditTags,
    EditorHeader,
    EditorInstall,
    FuncTab,
    EditorFolder
  },
  data() {
    return {
      optionsClaimClass: [
        {
          value: 'row',
          label: '表格行'
        },
        {
          value: 'cell',
          label: '单元格'
        },
        {
          value: 'paragraph',
          label: '段落'
        }
      ],
      claimClassValue: 'row',
      claimSuggestionsItems: [],
      inputClaimValue: 'claim1',
      ownerClassValue: 'row',
      pasteCheckDocIdMode: 3, //UserChoose
      pasteCheckBehaviorModeOptions: [
        {
          value: 0, //'NoCheck',
          label: '不检查'
        },
        {
          value: 1, // 'ForbidNeedNotify',
          label: '禁止粘贴并通知'
        },
        {
          value: 2, //'ForbidNoNotify',
          label: '禁止粘贴不通知'
        },
        {
          value: 3, //'UserChoose',
          label: '用户确定是否粘贴'
        }
      ],
      popDialogVisible: false,
      popDialogTitle: '11',
      popDialogText: '22',
      perfontData: {},
      /* 为方便演示，demo创建了4个EditorsTabsView.vue（各自包含1个ThinkEditorInstance对象）用于演示多组tabs的场景。每个
      应用应当结合框架自行编写EditorsTabsView.vue代码
      使用defaultEditorsTabsView 来保存第1个EditorsTabsView.vue，方便代码编写和用户理解
      --更简洁用法 参考vue3 demo
      */
      defaultEditorsTabsView: undefined,
      editorInstanceId1: 'editorInstance1',
      editorInstanceId2: 'editorInstance2',
      editorInstanceId3: 'editorInstance3',
      editorInstanceId4: 'editorInstance4',

      editorInstanceFocus1: true,
      editorInstanceFocus2: false,
      editorInstanceFocus3: false,
      editorInstanceFocus4: false,

      editorInstanceShow1: false,
      editorInstanceShow2: false,
      editorInstanceShow3: false,
      editorInstanceShow4: false,

      thinkEditorDemo: new ThinkEditorDemo(),
      modeType: '1',
      enableRevise: this.$enableRevise,
      useParseFontMode: this.$useParseFontMode,
      testMode: 0,
      formInline: {
        user: '',
        region: ''
      }
    };
  },
  async created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
    await this.PreLoadUserDefinedOptions();
    await this.PreLoadUserDefinedAlgorithms();
    this.$nextTick(async () => {
      /*************************************
       * ******************************** */
      this.defaultEditorsTabsView = this.GetEditorsTabsView(this.editorInstanceId1);
      this.openFirstDoc();
      /*
      if (IsBrowserSupportLocalFonts() && !(await CheckLocalFontsPermission())) {
        this.$alert('首次使用，请在随后弹出的提示框进行访问本机字体授权-点击"允许"', '提示', {
          confirmButtonText: '确定',
          callback: async action => {
            await fontsManager.TryUserGetLocalFontsPermission();
            this.openFirstDoc();
          }
        });
      } else {
        //浏览器不支持本地字体或获得权限
        this.openFirstDoc();
      }*/
    });
  },
  async mounted() {
    //if ('serviceWorker' in navigator) {
    //navigator.serviceWorker.register('..//ThinkEditorCache.js');
    //}
    this.claimSuggestionsItems = this.loadClaimSuggestionsItems();
    // 初始化默认的editor
    this.editorInstanceShow1 = true;
    //-------
    //await this.openFirstDoc();
    //-------

    this.$nextTick(() => {
      this.rowDrop();
    });

    // 获取可拖动元素和放置区域
    var draggerWidget = document.getElementById('draggerAndInsertElement');
    draggerWidget.addEventListener('dragstart', function (event) {
      editorLogger.LogDebug('dragstart');

      /*注意：参数完全由用户自定义，格式和内容可以附加其他信息*/
      let param = { draggerType: 'InsertElement' };
      event.dataTransfer.setData('data', JSON.stringify(param));
    });
    // 获取可拖动元素和放置区域
    draggerWidget = document.getElementById('draggerAndInputFragment');
    draggerWidget.addEventListener('dragstart', function (event) {
      /*注意：参数完全由用户自定义，格式和内容可以附加其他信息*/
      let param = { draggerType: 'InputFragment' };
      event.dataTransfer.setData('data', JSON.stringify(param));
    });
  },
  computed: {
    calcFirstRowHight() {
      if (!this.editorInstanceShow1 && !this.editorInstanceShow2) {
        return '0px';
      } else if (this.editorInstanceShow3 || this.editorInstanceShow4) {
        return '50%';
      } else {
        return '100%';
      }
    },
    calcSecondRowHight() {
      if (!this.editorInstanceShow3 && !this.editorInstanceShow4) {
        return '0px';
      } else if (this.editorInstanceShow1 || this.editorInstanceShow2) {
        return '50%';
      } else {
        return '100%';
      }
    }
  },
  methods: {
    OnEnableReviseChange(val) {
      this.$enableRevise = val;
    },
    OnFontParseMapChange(val) {
      this.$useParseFontMode = val;
    },

    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    GetEditorsTabsView(instanceId) {
      return this.$refs[instanceId];
    },
    OnOpenTimeAxisSetWindow() {
      this.thinkEditor.DisplayTimeAxisSetWindow();
    },
    GetFocusTabsView() {
      return this.GetEditorsTabsView(this.GetFocusEditorInstanceId());
    },

    GetFocusEditorInstanceId() {
      if (this.editorInstanceFocus1) {
        return this.editorInstanceId1;
      } else if (this.editorInstanceFocus2) {
        return this.editorInstanceId2;
      } else if (this.editorInstanceFocus3) {
        return this.editorInstanceId3;
      } else if (this.editorInstanceFocus4) {
        return this.editorInstanceId4;
      }
      return this.editorInstanceId1;
    },

    rowDrop() {
      const el = document.querySelector('.editor-container-box .el-tabs__nav'); //找到想要拖拽的那一列
      const _this = this;
      Sortable.create(el, {
        onEnd({ newIndex, oldIndex }) {
          //oldIIndex拖放前的位置， newIndex拖放后的位置  //tabOptionList为遍历的tab签
        }
      });
    },
    onSubmit() {
      console.log('submit!');
    },
    // 工具栏插入按钮点击事件
    getSelectOpera(typeItem) {
      let currentTabsView = this.GetFocusTabsView();
      currentTabsView.installToolClick(typeItem);
    },
    OnFuncClick(item) {
      let currentTabsView = this.GetFocusTabsView();
      currentTabsView.OnFuncClick(item);
    },
    ///
    async fetchDocData(docFile) {
      var docUrl = '/system/emr/' + docFile;
      /*
      if (docType == E_DOC_TYPE.Template) {
        docUrl = '/system/emr/' + docFile;
      } else if (docType == E_DOC_TYPE.Fragment) {
        docUrl = '/system/fragment/' + docFile;
      } else {
        //if (docType == E_DOC_TYPE.Entity)
        docUrl = '/system/emr/' + docFile;
      }*/
      var start = new Date().getTime();
      //应用自行规划xml文件存放路径
      let response = await fetch(docUrl, {
        credentials: 'same-origin'
      });
      let docData = await response.arrayBuffer();
      console.log('TimeCost下载文档:', `${new Date().getTime() - start}ms`);
      //editorLogger.LogDebug('TimeCost下载文档:', `${new Date().getTime() - start}ms`);
      return docData;
    },
    async toolbarClick(typeItem) {
      let currentTabsView = this.GetFocusTabsView();
      switch (typeItem.val) {
        case 'newDoc':
          //创建空文档编辑器
          let docEditor = await currentTabsView.NewDocEditor('', E_DOC_TYPE.Entity);
          //后续操作：示例，Tab页签切换显示当前文档
          currentTabsView.SelectDocEditor(docEditor.docName);
          break;
        case 'openDoc':
          this.thinkEditorDemo.OpenDoc();
          break;
      }

      let currentEditor = currentTabsView.GetSelectedEditor();
      if (currentEditor == undefined) {
        console.warn('当前未选中文档，操作无效!');
        return;
      }
      this.thinkEditor = currentEditor;
      this.thinkEditorDemo.bind(this.thinkEditor);
      switch (typeItem.val) {
        case 'saveDoc':
          this.thinkEditorDemo.SaveToXml();
          //var dataBuffer = this.thinkEditor.GetDoc(E_DOC_FORMAT.XML, E_DOC_TYPE.Entity);
          //将dataBuffer保存到数据库

          break;
        case 'saveAsTemplate':
          this.thinkEditorDemo.SaveToXml(E_DOC_TYPE.Template);
          break;

        case 'toHTML':
          this.thinkEditorDemo.SaveToHtml();
          break;
        case 'toPdf':
          this.thinkEditorDemo.SaveToPdf();
          break;
        case 'toImg':
          this.thinkEditorDemo.SaveToImg();
          break;
        case 'setPage':
          // 触发页面设置窗口
          this.thinkEditor.DisplayPageSetWindow(E_DISPLAY_MODE.Show);
          break;
        case 'cleanEdit':
          this.thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {
            hideElementHint: false
          });
          //this.thinkEditor.SetDocConfig('', { strictEdit: true });
          break;
        case 'reviseEdit':
          this.thinkEditor.SetEditMode(true, true, true, true);
          this.thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {
            hideElementHint: false,
            displayEditAuxiliary: true,
            displaySelectionEffect: true,
            displayReviseAuxiliary: true,
            displayReviseDel: true,
            displayQCInfobox: true,
            displayCommentInfobox: true,
            displayQCMode: true,
            displayCommentMode: true
          });
          break;
        case 'cleanBrowser':
          this.thinkEditor.SetViewMode(E_VIEW_MODE.Browse, {
            hideElementHint: true
          });
          break;
        case 'reviseBrowser':
          this.thinkEditor.SetViewMode(E_VIEW_MODE.Browse, {
            hideElementHint: true,
            displayEditAuxiliary: true,
            displaySelectionEffect: true,
            displayReviseAuxiliary: true,
            displayReviseDel: true,
            displayQCInfobox: false,
            displayCommentInfobox: false,
            displayQCMode: true,
            displayCommentMode: true
          });
          break;
        case 'cleanPrint':
          {
            //进入打印预览 可以使用如下代码
            //this.thinkEditor.SetViewMode(E_VIEW_MODE.Print, {
            //  hideElementHint: true
            //});
            let printCfg = {};
            //printCfg.printMode = 1; //0:视图打印 1：矢量打印
            printCfg.view = {
              mode: E_VIEW_MODE.Print,
              hideElementHint: true
            };
            this.thinkEditor.PrintDoc(printCfg);
          }
          break;
        case 'revisePrint':
          {
            let printCfg = {};
            printCfg.printMode = 1; //0:视图打印 1：矢量打印
            printCfg.view = {
              mode: E_VIEW_MODE.Print,
              hideElementHint: true,
              displayEditAuxiliary: false,
              displaySelectionEffect: false,
              displayReviseAuxiliary: true,
              displayReviseDel: true,
              displayQCInfobox: false,
              displayCommentInfobox: false,
              displayQCMode: true,
              displayCommentMode: true
            };
            this.thinkEditor.PrintDoc(printCfg);
          }
          break;
        case 'SelectPagePrint':
          {
            //this.thinkEditor.SetViewMode(E_VIEW_MODE.SelectPagePrint, {});
            let printCfg = {};
            printCfg.printMode = 1; //0:视图打印 1：矢量打印
            printCfg.view = {
              mode: E_VIEW_MODE.SelectPagePrint
            };
            this.thinkEditor.PrintDoc(printCfg);
          }
          break;
        case 'SelectContentPrint':
          {
            //this.thinkEditor.SetViewMode(E_VIEW_MODE.SelectContentPrint, {});

            let printCfg = {};
            printCfg.printMode = 1; //0:视图打印 1：矢量打印
            printCfg.view = {
              mode: E_VIEW_MODE.SelectContentPrint
            };
            this.thinkEditor.PrintDoc(printCfg);
          }
          break;
        case 'ContinuePrint':
          {
            //this.thinkEditor.SetViewMode(E_VIEW_MODE.ContinuePrint, {});

            let printCfg = {};
            printCfg.printMode = 1; //0:视图打印 1：矢量打印
            printCfg.view = {
              mode: E_VIEW_MODE.ContinuePrint
            };
            this.thinkEditor.PrintDoc(printCfg);
          }
          break;
        default:
          console.log(typeItem);
      }
    },
    /**
     * 生成格林威治时间
     * @returns {number}
     */
    now() {
      return (Date.now && Date.now()) || new Date().getTime();
    },

    async openFirstDoc() {
      let currentTabsView = this.GetFocusTabsView();
      let currentEditorsInstance = currentTabsView.editorsInstance;
      if (currentEditorsInstance.editors.length != 0) {
        //
        return;
      }

      //-for test
      /*******************************************************/

      /**************************选项*****************************/
      let optionIds = [];
      let optionId1 = new Option('系统_1', '身高');
      optionId1.items = [];
      optionId1.items.push(new OptionItem('高', '1', 1, ''));
      optionId1.items.push(new OptionItem('矮', '1', 1, ''));
      optionIds.push(optionId1);

      let optionId2 = new Option('系统_2', '体重');
      optionId2.items = [];
      optionId2.items.push(new OptionItem('胖', '1', 1, ''));
      optionId2.items.push(new OptionItem('瘦', '1', 1, ''));
      optionIds.push(optionId2);

      /*******************************************************/
      optionsManager.AddOptions(optionIds);
      /*******************************************************/
      /**************************算法*****************************/
      let algorithms = [];
      algorithms.push(new Algorithm('加法_1', E_ALGO_MODE.Addition, E_ALGO_SCOPE.Table));
      algorithms.push(new Algorithm('加法_2', E_ALGO_MODE.Common, E_ALGO_SCOPE.Doc));

      /*******************************************************/
      algorithmsManager.AddAlgorithms(algorithms);
      /*******************************************************/

      //-----------------
      await this.openDocMiniCode('演示', '演示\\00-演示病例\\AdmissionRecord.xml');
      // await this.openDocMiniCode('演示', '演示\\00-演示病例\\FontSizeTest.xml');
      //docEditor.thinkEditor.LoadRequestFont('ThinkHanSerifSC-Regular.ttf');
    },
    async openDocMiniCode(docName, docFile) {
      ///////////////////////////////////////////////////////////////
      // minidoc UI 部分代码，应用需结合自己的前端框架集成。DocEditor ThinkEditorInstanceHelper都不是必需的，是结合本VUE demo的UI tabs页签实现。第三方应用可参考相关UI实现思路。
      // Editor部分代码，为编辑器底层代码，不依赖前端框架。
      ///////////////////////////////////////////////////////////////
      /*├─js
        │ ThinkEditor.js //编辑器主程序 (必选)
        │ ThinkEditor.wasm //编辑器内核 (必选)
        │ ThinkEditor.Runtime.js //编辑器跨平台库 (必选)
        │ ThinkEditor.Defined.js //数据结构定义 (必选)
        │ ThinkEditor.Utils.js //公共方法 (必选)
        |
        │ ThinkEditor.Instance.js //多文档管理 (可选)
      */
      let currentTabsView = this.GetFocusTabsView();
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let docData = await this.fetchDocData(docFile);

      //UI: 1个Doc对应一个ThinkEditor,通过UI判断是否已经打开了同名文档
      let existEditor = currentTabsView.GetSelectedEditor(docName);
      if (existEditor !== undefined) {
        console.warn('无法创建：存在同名文档编辑器！docName:', docName);
        return;
      }

      //Editor: 初始化编辑器
      let thinkEditorParam = {
        lib: '/editor/',
        fontPath: '/editor_fonts/',
        docName: docName,

        logLevel: E_LOG_LEVEL.Debug, //E_LOG_LEVEL.Warn
        //debugMouseMove: true,
        useInnerUI: true,
        useInnerMenuBox: false,

        //auth: { mode: E_SYSTEM_AUTH_MODE.Demonstration },
        auth: { mode: E_SYSTEM_AUTH_MODE.Register, organs: '江东第一人民医院', company: '信译' },
        //defaultElementBeginBorder: '[',
        //defaultElementEndBorder: ']',
        forbidAutoCallSetInputFocus: true
        //forbidLocalFonts: true,
        //debugFont: false //不弹出”不支持本地字体”异常提示
      };

      var thinkEditor = new ThinkEditor(thinkEditorParam);
      thinkEditor.AddDocAttribute();
      await thinkEditor.Init();

      /**************************
       * 用户可以不使用DocEditor，可结合自己的应用系统 直接在thinkEditor上附加文档信息、用户信息等业务信息
       *示例：
       thinkEditor.userDefined = {
        docName:'文档名',
        docShowName:'文档UI tab显示名',
        docNo:'文档在业务系统标识',
        userNo:'用户、患者标识'
       };
       * ****************************/
      //thinkEditor.SetEditorConfig({ defaultElementBeginBorder: '[', defaultElementEndBorder: ']' });
      var authList = thinkEditor.GetEditorProperties();

      currentEditorsInstance.AddEditor(thinkEditor);
      /*UI: 1个Tab页签对应一个DocEditor 对应一个thinkEditor
        ThinkEditorView构建后自动this.thinkEditor.Load(this.$refs.editorContainer)显示在ThinkEditorView中
      */
      /*

*/
      currentTabsView.addInstanceEventListener(thinkEditor); //UI使用
      /*Editor: 预加载本文档资源
        note: 1个Doc拥有1个SourcesManager。本案示例由thinkEditor.sourcesManager管理
        OptionsManager、AlgorithmsManager 跨Doc管理。本案示例由window.optionsManager、window.algorithmsManager管理
      */
      await this.PreLoadUserDefinedSources(thinkEditor);

      //--------for test
      //await docEditor.thinkEditor.LoadRequestFont('ThinkHanSerifSC-Regular.ttf');
      if (this.$useParseFontMode) {
        let editorConfig = {};
        editorConfig.fontParseMap = [];
        editorConfig.fontParseMap.push({ src: '宋体', dest: '宋体' }); //使用微软宋体解析文档

        thinkEditor.SetEditorConfig(editorConfig);
      }
      //------------
      /*
        参考《开发手册》、《设计手册V2-最小代码说明》
      */
      thinkEditor.CreateDoc(docName);

      thinkEditor.SetEditorInfo('pc', '12666', 'admin', 1, { note: '上级医师审阅修改' });
      thinkEditor.SetDocType(E_DOC_TYPE.Entity);

      thinkEditor.ParseDoc(docName, docData, {});

      thinkEditor.SetEditMode(false, false, false, false);
      thinkEditor.SetDisplayScale(E_PAGES_LAYOUT_MODE.Ratio, 1.0);
      thinkEditor.SetDefaultFont('宋体', '小五');

      let editorConfig = {
        /*logLevel: this.$logLevel , debugMouseMove: true */
        optionMetaMode: 0
      };

      //实验性的
      //editorConfig.useInnerUI = true;
      //editorConfig.useInnerMenuBox = false;
      //editorConfig.fontsName = fontsManager.fontNameList; //await fontsManager.GetFontNameList();

      //end 实验性

      thinkEditor.SetEditorConfig(editorConfig);

      thinkEditor.SetPageConfig({
        width: 21,
        height: 29.7,
        id: '',
        name: '',
        notSave: false,
        alignVerticalLayout: 'Bottom',
        bindLine: {
          margin: 0,
          position: 16,
          showLine: false
        },
        bottomPadding: 2,
        leftPadding: 3.14,
        rightPadding: 3.14,
        topPadding: 2.54,
        header: {
          topMargin: 2,
          hide: 0,
          showLine: false,
          locked: false,
          dynamicLocked: false
        },
        footer: {
          bottomMargin: 0.3,
          hide: 0,
          showLine: false,
          locked: false,
          dynamicLocked: false
        },
        evenPageDiff: false,
        fristPageDiff: false
      });

      thinkEditor.SetViewMode(E_VIEW_MODE.Edit);

      let barcodeImageBase64String = barCodeImageBase64Str; //二维码图片base64编码
      var md5 = thinkEditor.SetImageResourceBase64('体检条形码.jpg', barcodeImageBase64String, barcodeImageBase64String.length, {});
      var signFragment = "<Fragment><Image width='2.5' height='1.0' file='体检条形码.jpg' md5='" + md5 + "'/></Fragment>";
      thinkEditor.SetEditorConfig({ disableEditLimit: 1 });
      thinkEditor.SetSources([new Source('门诊资料', '门诊号条形码', signFragment)]);

      thinkEditor.SetEditorConfig({ disableEditLimit: 0 });
      /*
      UI：tabs页签选择显示某个文档
      */
      currentTabsView.SelectDocEditor(docName);
    },
    async addTestListener(editorsInstance) {
      //for App.Button.js
      editorsInstance.addEventListener(E_EVENT_KEY.buttonPressed, this.OnButtonPressed);
    },
    /**
     * @function 创建1个 文档编辑实体 并 打开文档
     * @description 用于打开1个文档时调用
     * @param {String} docName - 文档名,不能为空。创建后docName不可更改，可以动态更改showName
     * @return {ThinkEditor} ThinkEditor - 文档编辑对象
     * @example
     *   CreateDocEditor("新文档名")
     */
    async OpenDoc(docName, docType, docData, nodeData, selected) {
      //step1: 选择要使用的编辑器实例(ThinkEditorInstance)
      let currentTabsView = this.GetFocusTabsView();

      //step2: 在该编辑器实例中创建1个文档编辑实体
      let thinkEditor = await currentTabsView.CreateDocEditor(docName);
      if (thinkEditor === undefined) {
        //文档名 可能重名
        return undefined;
      }
      await this.PreLoadUserDefinedSources(thinkEditor);

      //--------
      let currentEditorsInstance = currentTabsView.editorsInstance;
      await this.addTestListener(currentEditorsInstance); //for demo test
      //await docEditor.thinkEditor.LoadRequestFont('ThinkHanSerifSC-Regular.ttf');
      //------------
      //step3: OpenEmr打开文档通用封装，用户可自行完善
      await this.OpenEmr(thinkEditor, docName, docType, docData, nodeData);

      //step4: 选择显示该文档
      if (selected) {
        currentTabsView.SelectDocEditor(docName);
      }

      return thinkEditor;
    },
    async OpenDocFile(docName, docType, docFile, nodeData, selected) {
      let docData = await this.fetchDocData(docFile);
      return await this.OpenDoc(docName, docType, docData, nodeData, selected);
    },
    async DoDemoCase(nodeData, node) {
      this.testMode = nodeData.testMode;
      let currentTabsView = this.GetFocusTabsView();

      switch (nodeData.caseName) {
        case '获得打印数据(Electron打印)': {
          await this.DoDemoCaseGetPrintData();
          break;
        }
        case '多文档合并打印': {
          await this.DoDemoCaseMultDocPrint();
          break;
        }
        case '多文档合并(直接打印)': {
          await this.DoDemoCaseMultDocBackgroundPrint();
          break;
        }

        case '旋转打印': {
          await this.DoDemoCaseRotatePrint();
          break;
        }
        case '奇页打印': {
          await this.DoDemoCaseOddEvenPrint('奇页打印', true);
          break;
        }
        case '偶页打印': {
          await this.DoDemoCaseOddEvenPrint('偶页打印', false);
          break;
        }
        case '页码范围': {
          await this.DoDemoCasePagesRangePrint('1,2,4-5');
          break;
        }
        case '空元素不打印': {
          await this.DoDemoCaseHideElementHintPrint();
          break;
        }
        case '全文违禁字质控': {
          await this.DoDemoCaseQualityControl();
          break;
        }
        case '批注': {
          await this.DoDemoCaseComment();
          break;
        }
        case '痕迹报告': {
          await this.DoDemoCaseRevise();
          break;
        }
        case '大纲导航': {
          await this.DoDemoCaseOutline();
          break;
        }
        case '按钮点击事件': {
          await this.DoDemoCaseButton();
          break;
        }
        case '查找替换': {
          await this.DoDemoCaseSearchReplace();
          break;
        }
        case '水印': {
          await this.DoDemoCaseWaterMark();
          break;
        }
        case '视图比例': {
          await this.DoDemoCaseDisplayScale();
          break;
        }
        case '获取框选内容': {
          await this.DoDemoCaseGetSelectRangeContent();
          break;
        }
        case '对象是否打印': {
          await this.DoDemoCaseObjectHideNoDrawPrint();
          break;
        }
        case '拖拽插入': {
          await this.DoDemoCaseDragger();
          break;
        }
        case '行权限+签名': {
          await this.DoDemoCaseRowOwnerAndSign();
          break;
        }
        case '单元格、段落所属权限': {
          await this.DoDemoCaseOwner();
          break;
        }
        case '配置声明权限': {
          await this.DoDemoCaseConfigClaim();
          break;
        }
        case '行、单元格、段落声明权限': {
          await this.DoDemoCaseClaim();
          break;
        }
        case '同文档可粘贴': {
          await this.DoDemoCaseCheckDocId();
          break;
        }
        case '同用户可粘贴': {
          await this.DoDemoCaseCheckUserId();
          break;
        }
        case '禁止复制到外部': {
          await this.DoDemoCaseForbidCopyToOuter();
          break;
        }
        case '禁止粘贴外部内容': {
          await this.DoDemoCaseForbidPasteFromOuter();
          break;
        }
        case '动态生成体检报告': {
          await this.DoDemoCaseGenerateHealthReport();
          break;
        }
        default:
          break;
      }
    },
    async OpenSelectNodeDoc(docName, docPath, nodeData, node) {
      this.testMode = nodeData.testMode;

      let currentTabsView = this.GetFocusTabsView();

      docName = CalcDocName(docName);
      let existEditor = currentTabsView.editorsInstance.GetEditor(docName);
      if (existEditor !== undefined) {
        console.warn('无法创建：存在同名文档编辑器！docName:', docName);

        currentTabsView.SelectDocEditor(docName);

        return undefined;
      }

      if (nodeData.unionTest) {
        this.UnionTest(nodeData, node);
      } else {
        //打开单个文档
        //node.model.text, node.model.doc_type, node.model.emr, node.model.view_mode;
        let thinkEditor = await this.OpenDocFile(docName, E_DOC_TYPE.Entity, docPath, nodeData, true);
        if (thinkEditor !== undefined) {
          //thinkEditor.SetEditorConfig({ logLevel: logLevel });
        }
      }
    },

    // 点击一个实例
    OnShowMoreEditorInstance() {
      if (!this.editorInstanceShow1) {
        this.editorInstanceShow1 = true;
        this.EditorInstanceFocusChange(this.editorInstanceId1);
      } else if (!this.editorInstanceShow2) {
        this.editorInstanceShow2 = true;
        this.EditorInstanceFocusChange(this.editorInstanceId2);
      } else if (!this.editorInstanceShow3) {
        this.editorInstanceShow3 = true;
        this.EditorInstanceFocusChange(this.editorInstanceId3);
      } else if (!this.editorInstanceShow4) {
        this.editorInstanceShow4 = true;
        this.EditorInstanceFocusChange(this.editorInstanceId4);
      } else {
        this.$message({
          type: 'warning',
          message: '实例已达上限，无法新增更多'
        });
      }

      this.openFirstDoc();
    },

    OnHideLastEditorInstance() {
      // 判断那些box已显示
      // 判断下当前那个实例框未被使用
      if (this.editorInstanceShow4) {
        let editorInstance = this.GetEditorsTabsView(this.editorInstanceId4);
        editorInstance.CloseAllDocEditor();
        this.editorInstanceShow4 = false;
        this.EditorInstanceFocusChange(this.editorInstanceId3);
      } else if (this.editorInstanceShow3) {
        let editorInstance = this.GetEditorsTabsView(this.editorInstanceId3);
        editorInstance.CloseAllDocEditor();
        this.editorInstanceShow3 = false;
        this.EditorInstanceFocusChange(this.editorInstanceId2);
      } else if (this.editorInstanceShow2) {
        let editorInstance = this.GetEditorsTabsView(this.editorInstanceId2);
        editorInstance.CloseAllDocEditor();
        this.editorInstanceShow2 = false;
        this.EditorInstanceFocusChange(this.editorInstanceId1);
      } else {
        this.$message({
          type: 'warning',
          message: '至少保留1个ThinkEditorInstance'
        });
      }
    },
    OnCloseDocEditor() {
      let currentTabsView = this.GetFocusTabsView();
      let currentEditor = currentTabsView.GetSelectedEditor();
      if (currentEditor === undefined) {
        return;
      }
      currentTabsView.CloseDocEditor(currentEditor.docName);
    },
    OnCloseAllDocEditor() {
      let currentTabsView = this.GetFocusTabsView();
      currentTabsView.CloseAllDocEditor();
    },
    async OnDocOpt(opt) {
      switch (opt) {
        case 'openDoc': {
          await this.openDocTest();
          break;
        }
        case 'newEntityDoc': {
          let docData = await this.fetchDocData('空模版/empty.xml');
          var docName = 'new' + GetRandStr();
          return await this.OpenDoc(docName, E_DOC_TYPE.Entity, docData, {}, true);
          break;
        }
        case 'newTemplateDoc': {
          let docData = await this.fetchDocData('空模版/empty.xml');
          var docName = 'new' + GetRandStr();
          return await this.OpenDoc(docName, E_DOC_TYPE.Template, docData, {}, true);

          break;
        }
        case 'newTimeAxisDoc': {
          let docData = await this.fetchDocData('空模版/emptyTimeAxis.xml');
          var docName = 'new' + GetRandStr();
          return await this.OpenDoc(docName, E_DOC_TYPE.Template, docData, {}, true);

          break;
        }
      }
    },
    async openDocTest() {
      var docData = await PopFileSelector('.xml,');
      this.OpenDoc(docData.fileName, E_DOC_TYPE.Entity, docData.data, {}, true);
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;

  .el-container {
    height: 100vh;
    overflow: hidden;
  }

  .el-main {
    overflow: hidden;
    padding: 0;
  }
}

.treeborder {
  width: 300px;
  height: 100vh;
  background-color: #545c64;
  color: #fff;
  display: flex;
  padding: 10px;
  // border: 1px solid;
  border-radius: 0px;
  float: left;
  box-sizing: border-box;
}
.treeborder_right {
  position: relative;
  width: 100%;
  // height: calc(100vh - 520px);
  background-color: white;
  margin-top: 6px;
  //display: flex;
  // padding: 20px 10px;
  // border: 1px solid;
  // border-radius: 5px;
  float: left;
  //max-height:600px;
  //box-sizing: border-box;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.filter-box {
  display: flex;
}
.h-main {
  width: 100%;
  height: 50px;
  display: flex;
  /* 主轴方向，起点在左端 */
  flex-direction: row;
  /* 项目在主轴的对齐方式 */
  justify-content: flex-start;
}
.h-left {
  /* flex属性是flex-grow, flex-shrink 和 flex-basis的简写，
      默认值为0 1 auto。后两个属性可选。 */
  flex: 0 1 400px;
  height: 100%;

  /*background-color: red;*/
}
.h-right {
  flex: 0 1 100px;
  height: 100%;
  padding: 10px;
  /*background-color: pink;*/
}
.h-middle {
  flex: 1;
  height: 100%;
  padding: 10px;
  /*background-color: greenyellow;*/
}

.editor-container-box {
  height: calc(100% - 200px);

  .com-row {
    display: flex;

    .el-main {
      width: 50%;
      overflow: hidden;
    }
  }
}

.opear-btn {
  .el-tabs__content {
    overflow: visible;
  }
  height: 120px; //@20241218 在官网demo中 需固定个高度，防止挤压了 编辑器 高度（按道理不会挤压），导致横向滚动条显示不全bug
}

.el-tabs--border-card > .el-tabs__content {
  padding: 5px !important;
}
.el-popup-parent--hidden {
  padding-right: 0 !important;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
