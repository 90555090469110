import { TimeStr2TimeStamp, TimeStamp2TimeStr } from '@/components/editor/ThinkEditor.Utils';
import { editorLogger, optionsManager, algorithmsManager, E_UPDATE_PARAGRAPH_CFG_MODE, E_PAGES_LAYOUT_MODE, E_DOCS_ORGANIZE_MODE, Option, OptionItem, Source, E_ALGO_MODE, E_ALGO_SCOPE, Algorithm, E_VIEW_MODE, E_COMBINE_STYLE } from '@/components/editor/ThinkEditor.Defined';
import { tableDataMaterial } from './testmaterial/TableDataMaterial';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
import { FlushFrameData } from '@/components/editor/ThinkEditor.Runtime';
import { barCodeImageBase64Str } from '@/testmaterial/BarCodeImageBase64Str.js';
export const AppUserDefined = {
  methods: {
    OnCreatedDocEditor(e) {
      let thinkEditor = e.data.editor;

      //--------------------------------
    },

    OnRemovedDocEditor(e) {
      let thinkEditor = e.data.editor;

      //----------------------------------------------
    },
    EditorInstanceFocusChange(focusInstanceId) {
      editorLogger.LogDebug('EditorInstanceFocusChange:' + focusInstanceId);
      editorsInstanceManager.SelectedEditorsInstance(focusInstanceId);
      if (focusInstanceId == this.editorInstanceId1) {
        this.editorInstanceFocus1 = true;
        this.editorInstanceFocus2 = false;
        this.editorInstanceFocus3 = false;
        this.editorInstanceFocus4 = false;
      } else if (focusInstanceId == this.editorInstanceId2) {
        this.editorInstanceFocus1 = false;
        this.editorInstanceFocus2 = true;
        this.editorInstanceFocus3 = false;
        this.editorInstanceFocus4 = false;
      } else if (focusInstanceId == this.editorInstanceId3) {
        this.editorInstanceFocus1 = false;
        this.editorInstanceFocus2 = false;
        this.editorInstanceFocus3 = true;
        this.editorInstanceFocus4 = false;
      } else if (focusInstanceId == this.editorInstanceId4) {
        this.editorInstanceFocus1 = false;
        this.editorInstanceFocus2 = false;
        this.editorInstanceFocus3 = false;
        this.editorInstanceFocus4 = true;
      }
    },
    async PreLoadUserDefinedOptions() {
      //写法1
      let optionItems = new Array();
      optionItems.push(new OptionItem('选项1', '1', 1, ''));
      optionItems.push(new OptionItem('选项2', '1', 1, ''));
      optionItems.push(new OptionItem('选项3', '1', 1, ''));
      optionsManager.AddOption('自定义', '选项', optionItems, true);

      //写法2
      let options = new Array();

      let option1 = new Option('系统', '身高');
      option1.items = new Array();
      option1.items.push(new OptionItem('高', '1', 1, ''));
      option1.items.push(new OptionItem('矮', '1', 1, ''));
      options.push(option1);

      let option2 = new Option('系统', '体重');
      option2.items = new Array();
      option2.items.push(new OptionItem('胖', '1', 1, ''));
      option2.items.push(new OptionItem('瘦', '1', 1, ''));
      options.push(option2);

      let option3 = new Option('内分泌科', '症状');
      option3.items = new Array();
      option3.items.push(new OptionItem('腰痛', '1', 1, ''));
      option3.items.push(new OptionItem('便秘', '1', 1, ''));
      option3.items.push(new OptionItem('腹泻便秘交替', '1', 1, ''));
      option3.items.push(new OptionItem('意思障碍', '1', 1, ''));
      option3.items.push(new OptionItem('四肢确立', '1', 1, ''));
      options.push(option3);

      optionsManager.AddOptions(options);
    },

    async PreLoadUserDefinedAlgorithms() {
      algorithmsManager.AddAlgorithm('通用1', E_ALGO_MODE.Common, E_ALGO_SCOPE.Doc);
      //or
      let algorithms = new Array();
      algorithms.push(new Algorithm('加法1', E_ALGO_MODE.Addition, E_ALGO_SCOPE.Table));
      algorithms.push(new Algorithm('test1', E_ALGO_MODE.Common, E_ALGO_SCOPE.Doc));
      algorithmsManager.AddAlgorithms(algorithms);
    },
    async OpenEmr(thinkEditor, docName, docType, docData, nodeData) {
      if (nodeData.viewMode === undefined) {
        nodeData.viewMode = E_VIEW_MODE.Edit;
      }
      if (nodeData.viewCfg === undefined) {
        nodeData.viewCfg = {};
      }
      var editorConfig = {};
      if (nodeData.editorConfig !== undefined) {
        editorConfig = nodeData.editorConfig;
      }
      var docConfig = {};
      if (nodeData.docConfig !== undefined) {
        docConfig = nodeData.docConfig;
      }

      if (this.$useParseFontMode == 1) {
        //--------for test
        //await docEditor.thinkEditor.LoadRequestFont('ThinkHanSerifSC-Regular.ttf');

        editorConfig.fontParseMap = [];
        editorConfig.fontParseMap.push({ src: '宋体', dest: '宋体' }); //使用微软宋体解析文档
        //thinkEditor.SetEditorConfig(nodeData.editorConfig);
      }

      //------------
      /*
        参考《开发手册》、《设计手册V2-最小代码说明》
      */
      thinkEditor.CreateDoc(docName);

      thinkEditor.SetEditorInfo('pc', '12666', 'admin', 1, { note: '上级医师审阅修改' });
      thinkEditor.SetDocType(docType);
      //thinkEditor.SelectDoc(docName);
      thinkEditor.ParseDoc(docName, docData, {});

      if (nodeData.viewCfg.setEleTest) {
        let setDatas = [];

        // 页眉部分
        setDatas.push(
          // 页眉部分
          { id: 'dept_name', content: '科室1' },
          { id: 'bed_num', content: '床号1' },
          { id: 'hos_no', content: '问诊号1' },

          // 正文部分
          { id: 'patient_name', content: '张三1' },
          { id: 'gender', content: '女1' },
          { id: 'age', content: '16' /*calculateAge(this.patientData) + ''*/ },
          { id: 'nation', content: '汉1' }, // 民族
          { id: 'marital_status', content: '未婚1' },
          { id: 'native_place', content: '四川 成都1' }, // 籍贯
          { id: 'profession', content: '职业1' }, // 职业
          { id: 'family_address', content: '现住址1' }, // 现住址

          { id: 'in_hos_date', content: '2023-MM-DD HH:mm:ss' },
          { id: 'out_hos_time', content: '2024-MM-DD HH:mm:ss' }, // todo add 24.2.22
          { id: 'in_hos_days', content: '12' }
        );
        thinkEditor.SetElementsContent(setDatas);
      }
      if (this.$enableRevise == 1) {
        thinkEditor.SetEditMode(true, true, true, true);
      } else {
        thinkEditor.SetEditMode(false, false, false, false);
      }

      thinkEditor.SetDisplayScale(E_PAGES_LAYOUT_MODE.Ratio, 1.0);
      thinkEditor.SetDefaultFont('宋体', '小五');

      //editorConfig.logLevel = this.$logLevel;
      //editorConfig.logLevelDebugFlush = this.$debugFlush;

      if (this.$useParseFontMode) {
        editorConfig.fontParseMap = [];
        editorConfig.fontParseMap.push({ src: '宋体', dest: '宋体' }); //
      }
      editorConfig.pasteUpdateParagraphCfgMode = E_UPDATE_PARAGRAPH_CFG_MODE.ForceNoUpdate;
      thinkEditor.SetEditorConfig(editorConfig);
      thinkEditor.SetDocConfig(docName, docConfig);
      //let view_cfg = { displayReviseAuxiliary: true, displayReviseDel: true };
      thinkEditor.SetViewMode(nodeData.viewMode, nodeData.viewCfg);
    },
    /**
     * @function PreLoadUserDefinedSources 加载文档的源。本函数，需应用结合自己系统，修改源码 获取需要加载的源
     * @description [支持异步获取源]应用可根据docName、docType等信息,在解析文档ParseDoc前,加载 该文档可能会用到的源
     * @param {DocEditor} editor
     * @return {void}
     * @example
     *
     */
    async PreLoadUserDefinedSources(editor) {
      let sources = [];
      if (editor.docName == '测试1' /*&& docEditor.docType == E_DOC_TYPE.Entity*/) {
        sources.push(new Source('测试c1', '测试c1i1', '测试c1i1'));
        sources.push(new Source('测试c1', '测试c1i2', '测试c1i2'));
        sources.push(new Source('测试c1', '测试c1i3', '测试c1i3'));

        sources.push(new Source('测试c2', '测试c2i1', '测试c2i1'));
        sources.push(new Source('测试c2', '测试c2i2', '测试c2i2'));
      } else {
        sources.push(new Source('认证', '机构名', "<Fragment><Format size='0.776' />江东第一人民医院</Fragment>"));

        /********************病人基础资料*************************/
        sources.push(new Source('基本资料', '姓名', '张小北'));
        sources.push(new Source('基本资料', '性别', '男'));
        sources.push(new Source('基本资料', '身份证', '5131246845698xxxxx'));
        sources.push(new Source('基本资料', '年龄', '23'));
        sources.push(new Source('基本资料', '民族', '汉'));
        sources.push(new Source('基本资料', '医保号', '1982309'));

        //--------
        //this.thinkEditor.SetSource("门诊资料", "门诊号", "237899");
        sources.push(new Source('门诊资料', '门诊号', "<Fragment><BarCode width='1.50' height='1.50' cfg='13a'>MTIzNDU2Nzg5</BarCode></Fragment>")); // "237899");

        sources.push(new Source('住院资料', '住院号', 'new163679')); //163679
        sources.push(new Source('住院资料', '科室', '骨科'));
        sources.push(new Source('住院资料', '病房', '6'));
        sources.push(new Source('住院资料', '床号', '16'));
        sources.push(new Source('住院资料', '入院时间', '2019-09-09 09:16:23'));

        /***********************************
         * 设置图片源-此处用作签名
         * md5为图片二进制数据对应的md5码
         * 注意：图片数据在"imageResource.request"事件中调用SetImageResource/SetImageResourceBase64设置
         * 详见《设计手册-图像》
         * ************************************/
        var md5 = 'a04cfa91f403f799ce07252e85bda12b'; //md5必需为图片真实md5值
        var signSource = "<Fragment><Image width='2' height='0.8' file='当前医师电子签名.jpg' md5='" + md5 + "'/></Fragment>";
        sources.push(new Source('医师资料', '电子签名', signSource));
        /*******************上级医师********************/
        md5 = 'b3aa75a6c69fb17ec3cc4290bbea8a57'; //md5必需为图片真实md5值
        signSource = "<Fragment><Image width='2' height='0.8' file='审核医师电子签名.jpg' md5='" + md5 + "'/><Format color='c93756' size='0.6'/>/</Fragment>";
        sources.push(new Source('医师资料', '审核医师签名', signSource));

        sources.push(new Source('自定义c1', '自定义c1i1', 'c1i1'));
        sources.push(new Source('自定义c1', '自定义c1i2', 'c1i2'));
        sources.push(new Source('自定义c1', '自定义c1i3', 'c1i3'));

        sources.push(new Source('自定义c2', '自定义c2i1', 'c2i1'));
        sources.push(new Source('自定义c2', '自定义c2i2', 'c2i2'));

        //-------------
        sources.push(new Source('系统', '省', ''));
        sources.push(new Source('系统', '市', ''));
        sources.push(new Source('系统', '区', ''));
      }

      //barCodeImageBase64Str是为了演示而预设的一个测试数据，应用自行动态更新
      let barcodeImageBase64String = barCodeImageBase64Str; //二维码图片base64编码
      var md5 = editor.SetImageResourceBase64('体检条形码.jpg', barcodeImageBase64String, barcodeImageBase64String.length, {});
      var signFragment = "<Fragment><Image width='2.5' height='1.0' file='体检条形码.jpg' md5='" + md5 + "'/></Fragment>";
      sources.push(new Source('门诊资料', '门诊号条形码', signFragment));

      //editor.sourcesManager.AddSources(sources);
      editor.SetSources(sources);
    },
    createTimeDataSimulate(startTimeStr, add) {
      //一个文档中 只有一个TimeAxis

      //取得当前时间轴的结束时间（有多种时间点数据时，以最晚的时间点为准）
      let timeStamp = TimeStr2TimeStamp(startTimeStr); // "2021-09-09 00:00:00"=》时间的时间戳; //

      let datasCfg = [];

      let timeDataTiWen = { id: 'tiwen', mode: add, timeData: [] };

      for (let i = 0; i < 10; i++) {
        timeStamp += 14400; //14400秒等于240分=4小时
        let timePoint = {
          time: TimeStamp2TimeStr(timeStamp), //=>"2021-09-09 00:00:00"
          value: 34.5 + Math.floor(Math.random() * 10 + 0),
          lanternValue: 34.0 + Math.floor(Math.random() * 10 + 0)
        };
        timeDataTiWen.timeData.push(timePoint);
      }
      datasCfg.push(timeDataTiWen);
      return datasCfg;
    },
    async OnSetTimeDataAppendTest() {
      //示例：构造测试数据
      let timeAxisCfg = this.thinkEditor.GetTimeAxisConfig('');
      //以当前所有数据点的结束作为 模拟数据的起始+追加模式
      let appendTime = timeAxisCfg.endTime;
      for (let i = 0; i < timeAxisCfg.timeDatas.length; i++) {
        if (timeAxisCfg.timeDatas[i].id == 'tiwen') {
          appendTime = timeAxisCfg.timeDatas[i].endTime;
          break;
        }
      }
      let timeDatas = this.createTimeDataSimulate(appendTime, true);

      //[注意]：实际使用，都是从第三方应用系统数据 生成的timeDatas，不需要关心startTime、endTime 以实际要显示的数据为准
      //---------------------
      this.thinkEditor.SetTimeData(timeDatas);
      this.thinkEditor.UpdateTimeAxis('');
    },
    async OnSetTimeDataUpdateTest() {
      //示例：构造测试数据
      let timeAxisCfg = this.thinkEditor.GetTimeAxisConfig('');
      //以时间轴配置的起始时间作为 模拟数据的起始+重设模式
      let timeDatas = this.createTimeDataSimulate(timeAxisCfg.beginTime, false);
      //[注意]：实际使用，都是从第三方应用系统数据 生成的timeDatas，不需要关心startTime、endTime 以实际要显示的数据为准
      //---------------------
      this.thinkEditor.SetTimeData(timeDatas);
      this.thinkEditor.UpdateTimeAxis('');
    },
    async OnSetTableDataAppendTest() {
      this.thinkEditor.UpdateEditorInfo('L76dT6001', 'pc', '9356', 'xy', 1, '1669018602', {});
      //示例：构造测试数据(Table Xml格式)

      let tableXml = tableDataMaterial;

      this.thinkEditor.SetTableData('HLJL_MRPG', tableXml, { mode: 1 });
    },
    async OnSetTableDataUpdateTest() {
      this.thinkEditor.UpdateEditorInfo('L76dT6001', 'pc', '9356', 'xy', 1, '1669018602', {});
      //示例：构造测试数据(Table Xml格式)
      let tableXml = tableDataMaterial;

      this.thinkEditor.SetTableData('HLJL_MRPG', tableXml, {});
    },
    async OnSetCellDataTestStyle1(imgCnt, colsCnt) {
      var configData = {};
      configData.style = E_COMBINE_STYLE.Style1;
      configData.cols = colsCnt; //一行最多colsCnt张图片
      configData.units = [];

      for (var i = 1; i <= imgCnt; i++) {
        var testImageName = 'PACS(' + i + ').jpg';
        var url = '/system/test/pacs/' + testImageName;
        let response = await fetch(url, { credentials: 'same-origin' });
        let imgData = await response.arrayBuffer();

        var md5 = this.thinkEditor.SetImageResource(testImageName, imgData, imgData.byteLength, {});
        var unit = {};
        unit.md5 = md5;
        if (i == 1) {
          //for test: 文字局部带颜色
          unit.fragment = '<Fragment><Font color="00ff00"/>图片1</Fragment>';
        } else {
          unit.fragment = testImageName;
        }
        configData.units.push(unit);
      }
      configData.cellId = 'pacsImg'; //cellId
      this.thinkEditor.SetCellContent(configData);
    },
    async OnSetCellDataTestCommon(imgCnt, colsCnt) {
      var configData = {};
      configData.style = E_COMBINE_STYLE.Common;
      configData.cols = colsCnt; //一行最多colsCnt张图片
      configData.units = [];

      for (var i = 1; i <= imgCnt; i++) {
        var testImageName = 'Img(' + i + ').jpg';
        var url = '/system/test/超声/' + testImageName;
        let response = await fetch(url, { credentials: 'same-origin' });
        let imgData = await response.arrayBuffer();

        var md5 = this.thinkEditor.SetImageResource(testImageName, imgData, imgData.byteLength, {});
        var unit = {};
        unit.md5 = md5;
        if (i == 1) {
          //for test: 文字局部带颜色
          unit.fragment = '<Fragment><Font color="00ff00"/>图片1</Fragment>';
        } else {
          unit.fragment = testImageName;
        }
        configData.units.push(unit);
      }
      configData.cellId = 'pacsImg'; //cellId
      this.thinkEditor.SetCellContent(configData);
    },
    async OnDynamicOptionsTest(testMode) {
      //let optionsManager = window.optionsManager;
      if (testMode == 1) {
        //今日患者
        /*
        let optionItems = new Array();
        optionItems.push(new OptionItem('张三', '1', 1, ''));
        optionItems.push(new OptionItem('李四', '1', 1, ''));
        optionItems.push(new OptionItem('王五', '1', 1, ''));
        optionsManager.AddOption('医生', '当前患者', optionItems);
        this.thinkEditor.SetOptions();*/

        let option = new Option('医生', '当前患者');
        option.items = new Array();
        option.items.push(new OptionItem('张三', '1', 1, ''));
        option.items.push(new OptionItem('李四', '1', 1, ''));
        option.items.push(new OptionItem('王五', '1', 1, ''));

        optionsManager.AddOptions([option], true);
        this.thinkEditor.SetOptions([option]);
      } else {
        /*let optionItems = new Array();
        optionItems.push(new OptionItem('昨日患者1', '1', 1, ''));
        optionItems.push(new OptionItem('昨日患者2', '1', 1, ''));
        optionItems.push(new OptionItem('昨日患者3', '1', 1, ''));
        optionsManager.AddOption('医生', '当前患者', optionItems);*/

        let option = new Option('医生', '当前患者');
        option.items = new Array();
        option.items.push(new OptionItem('昨日患者1', '1', 1, ''));
        option.items.push(new OptionItem('昨日患者2', '1', 1, ''));
        option.items.push(new OptionItem('昨日患者3', '1', 1, ''));

        optionsManager.AddOptions([option], true);
        this.thinkEditor.SetOptions([option]);
      }
    },
    async sleep(seconds) {
      return new Promise(resolve => setTimeout(resolve, seconds));
    },
    async UnionDocs1(odeData, node) {
      let docName = '病程记录合并';
      let currentTabsView = this.GetFocusTabsView();
      let currentEditorsInstance = currentTabsView.editorsInstance;
      //step2: 在该编辑器实例中创建1个文档编辑实体
      let thinkEditor = await currentTabsView.CreateDocEditor(docName);
      if (thinkEditor === undefined) {
        //创建失败，说明文档已存在
        return currentTabsView.SelectDocEditor(docName);
      }
      //await this.sleep(2000);
      thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
      thinkEditor.SelectDoc(docName);
      thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');

      let basePath = '/system/emr/测试用例/合并打印';
      // var url = basePath + `/测试2.xml`;
      // let response = await fetch(url, {
      //   credentials: 'same-origin'
      // });
      // let xml = await response.arrayBuffer();
      //let subDocNames = ['入院记录3', '术前小结3', '手术风险评估单3', '病程记录3', '病程记录2 (2)', '病程记录2', '测试2'];
      let subDocNames = ['入院记录3', '术前小结3', '手术风险评估单3', '病程记录头3', '病程记录1', '病程记录2', '病程记录3', '病程记录4', '病程记录5', '病程记录6', '病程记录7', '病程记录8', '病程记录9', '病程记录10', '病程记录11', '病程记录12', '病程记录13', '病程记录14', '病程记录15', '病程记录16', '病程记录17', '病程记录18'];
      for (let i = 0; i < subDocNames.length; i++) {
        let subDocName = subDocNames[i];
        var url = basePath + `/${subDocName}.xml`;
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();
        // await this.sleep(2000);
        thinkEditor.ParseDocs(docName, subDocName, xml, { organizeMode: i <= 3 ? E_DOCS_ORGANIZE_MODE.UnionSection : E_DOCS_ORGANIZE_MODE.UnionContent });
      }
      FlushFrameData(thinkEditor);
      thinkEditor.SetViewMode(E_VIEW_MODE.Browse, {});

      //step4: 选择显示该文档
      currentTabsView.SelectDocEditor(docName);

      return thinkEditor;
    },
    async UnionDocs(odeData, node) {
      //await this.UnionDocs1(odeData, node);
      //return; //for test
      let docName = '张三 病程记录';
      let currentTabsView = this.GetFocusTabsView();
      let currentEditorsInstance = currentTabsView.editorsInstance;
      //step2: 在该编辑器实例中创建1个文档编辑实体
      let thinkEditor = await currentTabsView.CreateDocEditor(docName);
      if (thinkEditor === undefined) {
        //创建失败，说明文档已存在
        return currentTabsView.SelectDocEditor(docName);
      }
      //await this.sleep(2000);

      let basePath = '/system/emr/' + node.parent.parent.label + '/' + node.parent.label;
      let subDocName = '张三 2021-09-09 病程记录';
      var url = basePath + `/${subDocName}.xml`;
      let response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xml1 = await response.arrayBuffer();

      let json_cfg = {};
      json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionSection;
      //json_cfg.recoverSelection = 2;
      //json_cfg.setUnionSelection = 0;
      thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
      thinkEditor.SelectDoc(docName);
      thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');
      thinkEditor.ParseDocs(docName, subDocName, xml1, json_cfg);
      //await this.sleep(2000);
      subDocName = '张三 2021-09-10 病程记录';
      url = basePath + `/${subDocName}.xml`;
      response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xml2 = await response.arrayBuffer();
      let jsonCfg2 = { organizeMode: E_DOCS_ORGANIZE_MODE.UnionContent, locked: true };
      //json_cfg.recoverSelection = 2;
      //json_cfg.setUnionSelection = 1;
      thinkEditor.ParseDocs(docName, subDocName, xml2, jsonCfg2);
      //await this.sleep(2000);
      subDocName = '张三 2021-09-11 病程记录';
      url = basePath + `/${subDocName}.xml`;
      response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xml3 = await response.arrayBuffer();
      json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionSection;

      //json_cfg.setUnionSelection = 0;
      thinkEditor.ParseDocs(docName, subDocName, xml3, json_cfg);
      //await this.sleep(2000);
      subDocName = '张三 2021-09-12 病程记录';
      url = basePath + `/${subDocName}.xml`;
      response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xml4 = await response.arrayBuffer();
      json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionContent;
      //json_cfg.recoverSelection = 2;
      //json_cfg.setUnionSelection = 0;
      thinkEditor.ParseDocs(docName, subDocName, xml4, json_cfg);
      //await this.sleep(2000);
      subDocName = '张三 2021-09-13 病程记录';
      url = basePath + `/${subDocName}.xml`;
      response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xml5 = await response.arrayBuffer();
      json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionContent;
      //json_cfg.recoverSelection = 2;
      //json_cfg.setUnionSelection = 0;
      thinkEditor.ParseDocs(docName, subDocName, xml5, json_cfg);
      //await this.sleep(2000);
      thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

      //step4: 选择显示该文档
      currentTabsView.SelectDocEditor(docName);

      return thinkEditor;
    },
    async SelectSubDoc(nodeData, node) {
      let currentTabsView = this.GetFocusTabsView();
      let currentEditorsInstance = currentTabsView.editorsInstance;
      //step2: 在该编辑器实例中创建1个文档编辑实体

      let docName = '张三 病程记录';
      currentTabsView.SelectDocEditor(docName);
      let thinkEditor = currentEditorsInstance.GetEditor(docName);
      if (thinkEditor === undefined) {
        thinkEditor = await this.UnionDocs(nodeData, node);
      }

      thinkEditor.SetDocConfig(docName, { selectSubDoc: node.label }); // node.label=张三 2021-09-13 病程记录
    },
    async UnionTest(nodeData, node) {
      let unionTest = nodeData.unionTest;
      if (unionTest.doUnion) {
        await this.UnionDocs(nodeData, node);
      } else if (unionTest.selectSubDoc) {
        await this.SelectSubDoc(nodeData, node);
      }
    }
  }
};
